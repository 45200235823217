import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import { toast } from 'react-toastify'
import AgentLog from './agentLog'
import AllLog from './allLog'
import Loading from './images/loading40.gif'


toast.configure()
const cookies = new Cookies();

export default function Banking(props) {
	const [agents, setagents] = useState([]);
	const [arrIndex, setarrIndex] = useState(-1);
	const [bankingType, setbankingType] = useState('no');
	const [agentPass, setagentPass] = useState('');
	const [updated, setupdated] = useState(false);
	const [showAgentLog, setshowAgentLog] = useState(false);
	const [selectedAgent, setselectedAgent] = useState('');
	const [showAllLog, setshowAllLog] = useState(false);
	const [refresh, setrefresh] = useState(true);
	const [creditref, setcreditref] = useState('');



	const changeWaiting = (bool) => {
		setrefresh(bool);
	};

	const changeAgentLog = () => {
		setshowAgentLog(false);
	}

	const changeAllLog = () => {
		setshowAllLog(false);
	}

	var sum_A1 = 0, sum_B1 = 0, sum_C1 = 0, sum_D1 = 0, sum_D2 = 0;


	useEffect(() => {



		var ssid = cookies.get('sid');
		if (!ssid) return;

		axios.post('https://flagapi.in/agentapi/newgetDownlineDetaliInfo', {
			sid: ssid,

		})
			.then(result => {

				if (result.status === 200) {

					let arr = [];
					for (let key in result.data) {

						if (result.data.hasOwnProperty(key)) {
							result.data[key].user = key;
							result.data[key].banking = 'no';
							result.data[key].amount = '';
							result.data[key].remark = '';
							result.data[key].changeCf = 'no';
							arr.push(result.data[key]);

						}
					}

					setagents(arr);
					if (refresh) {
						setrefresh(false);
					}
				}
			}

			).catch(e => {
				//setIsError(true);
			});


	}, [updated]);

	const updateDeposit = (index) => {
		var arr = [...agents];
		arr.map((item, index) => {
			arr[index].changeCf = 'no';
		})
		if (arrIndex === index && bankingType === 'D') {
			setarrIndex(-1);
			setbankingType('no');
		}
		else {
			setarrIndex(index);
			setbankingType('D');
		}
	}

	const updateWithdraw = (index) => {
		/*var arr = [...agents];
		if(arr[index].banking === 'W'){
		  arr[index].banking = 'no';
		  
		}
		else{
		  arr[index].banking = 'W';

		}
		setagents(arr);

	   */
		var arr = [...agents];

		arr.map((item, index) => {
			arr[index].changeCf = 'no';
		})

		if (arrIndex === index && bankingType === 'W') {
			setarrIndex(-1);
			setbankingType('no');
		}
		else {
			setarrIndex(index);
			setbankingType('W');
		}

	}

	const updateAmount = (val, index) => {
		var arr = [...agents];

		arr[index].amount = val;

		setagents(arr);

	}

	const updateRemark = (val, index) => {
		var arr = [...agents];

		arr[index].remark = val;

		setagents(arr);

	}

	const updateCf = (val, index) => {
		var arr = [...agents];


		arr[index].changeCf = val;


		setcreditref(arr[index].cf);
		setagents(arr);

	}

	const changeCreditRef = (val, index) => {
		setcreditref(val);

	}

	const submitPayment = () => {

		if (agentPass === '' || agentPass === ' ') {
			toast.warn('Password can not be blank! ', { position: toast.POSITION.TOP_CENTER })
			return;
		}
		else if (arrIndex < 0) {
			toast.warn('Please select an agent', { position: toast.POSITION.TOP_CENTER })
			return;
		}
		else if (isNaN(parseFloat(agents[arrIndex].amount))) {
			toast.warn('Please select a valid amount', { position: toast.POSITION.TOP_CENTER })
			return;
		}
		else if (agents[arrIndex].amount <= 0) {
			toast.warn('Please enter a positive amount', { position: toast.POSITION.TOP_CENTER })
			return;
		}
		else if ((parseFloat(agents[arrIndex].amount) > parseFloat(agents[arrIndex].balance)) && bankingType === 'W') {
			toast.warn('Amount can not be more than Available D/W', { position: toast.POSITION.TOP_CENTER })
			return;
		}


		//  setloading(true);
		//  props.checkUpdateBalance(); 
		// setTimeout(()=>{setloading(false);},1000);



		var ssid = cookies.get('sid');
		if (!ssid) return;
		//	 setrefresh(true);
		axios.post('https://flagapi.in/agentapi/NewagentBanking', {
			sid: ssid,
			agentId: agents[arrIndex].user,
			bankingType: bankingType,
			amount: agents[arrIndex].amount,
			remark: agents[arrIndex].remark,
			myPass: agentPass,
			updateCF: agents[arrIndex].changeCf,
			creditVal: creditref

		})
			.then(result => {

				if (result.status === 200) {

					setrefresh(true);
					setTimeout(() => { setrefresh(false); }, 500);

					toast.success('Agent balance updated successfully!', { position: toast.POSITION.TOP_CENTER })
					clearAll();
					props.checkUpdateBalance();
					setupdated(!updated);
				}
				else if (result.status === 211) {
					toast.warn('Wrong password!', { position: toast.POSITION.TOP_CENTER })
				}
				else {
					console.log(result.status, '2');
					toast.warn('Agent balance update failed!', { position: toast.POSITION.TOP_CENTER })

				}


			}

			).catch(e => {
				//setIsError(true);
			});

		//arr[index].remark = val;

	}


	const clearAll = () => {
		setarrIndex(-1);
		setbankingType('no');
		setagentPass('');

		var arr = [...agents];
		arr.map((item) => {
			item.amount = '';
			item.remark = '';
		})

		setagents(arr);

	}



	return (

		<React.Fragment>

			{refresh && <div id="loading" class="loading-wrap" style={{}}>
				<ul class="loading">
					<li>
						<img src={Loading} />
					</li>
					<li>Loading...</li>
				</ul>
			</div>}

			<div class="full-wrap" style={{ height: 'calc(100% - 105px)' }}>
				<h2>Agent Banking</h2>

				<div className="total_all">
					{/* Message */}
					<div id="message" className="message-wrap success">
						<a className="btn-close">Close</a>
						<p />
					</div>
					<div className="search-wrap" id="userSearchUl">
						<div>

							<input className="search-input" type="text" name="searchUserId" id="searchUserId" placeholder="Enter userId..." />
						</div>
					</div>
					<ul className="input-list" id="companySearchUl">
						<li>
							<a className="btn-send" href="#" id="search">Search</a>
						</li>
					</ul>
					<ul className="input-list" id="accountStatusUl">
						<li><strong>Status</strong></li>
						<li>
							<select className name="accountStatus" id="accountStatus">
								<option value={0}>ACTIVE
								</option>
								<option value={1}>SUSPENDED
								</option>
								<option value={2}>LOCKED
								</option>
								<option value={-1}>ALL</option>
							</select>
						</li>
					</ul>
				</div>
				{/* <!-- Report Table --> */}
				<div class="over-wrap" style={{ maxHeight: 'calc(100% - 108px)' }}>

					<div class="function-wrap clearfix">
						<dl class="banking-head float-L">
							<dt>Your Balance</dt>
							<dd id="yourBalance"><span>PBU</span>{parseFloat(props.balance).toFixed(2)}</dd>
						</dl>
						<ul class="input-list float-R" style={{ display: 'none' }}>
							<li><label>Sort by</label></li>
							<li>
								<select id="sort">
									<option value="userId">AgentID</option>
									<option value="site">Site</option>
									<option value="currencyType">Currency</option>
								</select>
							</li>
						</ul>
					</div>


					<table id="table_transfer" class="table01 tab-transfer tab-banking">
						<tbody><tr>
							<th id="userIdTH" width="" class="align-L sort-this">AgentID</th>
							<th id="siteTH" width="5%" style={{ display: 'none' }}>Site</th>
							<th id="currencyTypeTH" width="5%" style={{ display: 'none' }}>Currency</th>
							<th width="9%">Balance</th>
							<th width="9%">Available D/W</th>
							<th width="9%">Exposure</th>
							<th width="230" class="align-C border-l">Deposit / Withdraw</th>

							<th class="border-l">Credit Reference</th>
							<th class="">Reference P/L</th>

							<th width="10%" class="border-l">Remark</th>
							<th width="47" class="full-amount">
								<a onClick={() => { setshowAllLog(true); }} id="allLog" class="btn-send">All Log</a>
							</th>
						</tr>
						</tbody>
						<tbody id="content">

							{agents.map((item, index) => {
								var lev;
								var cla;
								if (item.level == 1) {
									lev = 'WL';
									cla = '1';
								}
								else if (item.level == 2) {
									lev = 'SSS';
									cla = '2';
								}
								else if (item.level == 3) {
									lev = 'SS';
									cla = '3';
								}
								else if (item.level == 4) {
									lev = 'SUP';
									cla = '4';
								}
								else if (item.level == 5) {
									lev = 'MA';
									cla = '5';
								}

								//    let b = item.balance ? item.balance : 0;
								//    let ld = item.ldb ? item.ldb : 0;
								let lw = item.lwc ? item.lwc : 0;


								var Exposure = item.Exposure;
								let ld = parseFloat(item.Balance);
								var b = parseFloat(item.AvlBalance);
								var avail_balance = parseFloat(b);
								let cr = avail_balance - parseFloat(item.cfBalance);
								sum_A1 = sum_A1 + avail_balance;
								sum_B1 = sum_B1 + parseFloat(ld);
								sum_C1 = sum_C1 + parseFloat(Exposure);
								sum_D1 = sum_D1 + parseFloat(0.00);
								sum_D2 = sum_D2 + 0.00;

								return (
									<tr key={index} id="akshayddl" main_userid="akshayddl">
										<td class="td-uid" id="userId"><span class="order">{index + 1}.</span><span style={{ marginTop: '4px' }} className={`lv_${true ? cla : " "}`}>{lev}</span>{item.user}</td>
										<td id="siteCol" style={{ display: 'none' }}>Sky</td>
										<td id="currencyTypeCol" style={{ display: 'none' }}>PBU</td>
										<td id="balance">{parseFloat(b).toFixed(2)}</td>
										<td id="availableBalance">{parseFloat(ld).toFixed(2)}</td>
										<td id="userExposure" class="red" >{'(' + parseFloat(Math.abs(Exposure)).toFixed(2) + ')'}</td>
										<td class="DW-amount">
											<ul className={`btn_list-DW ${index === arrIndex && bankingType === 'D' ? "select-D" : ""} ${index === arrIndex && bankingType === 'W' ? "select-W" : ""}`}>
												<li><a onClick={() => { updateDeposit(index); }} id="dBtn" class="btn" >D</a></li>
												<li><a onClick={() => { updateWithdraw(index); }} id="wBtn" class="btn" >W</a></li>
											</ul>
											<input id={"amount" + index} onChange={(e) => { updateAmount(e.target.value, index); }} className={`${index === arrIndex && bankingType === 'W' ? "type-w" : ""}`} name="amount" value={item.amount} type="number" placeholder="0" maxLength="18" />
											<a id="fullBtn" onClick={() => { if (index === arrIndex && bankingType === 'W' && b != 0) updateAmount(b, index); }} className={`btn float-L ${index === arrIndex && bankingType === 'W' ? "" : "disable"}`} >Full</a>
										</td>

										<td class="credit-amount" style={{ width: '170px' }}>
											{item.changeCf == 'no' && <a id="userCreditReference" style={{}}>{item.cfBalance}</a>}
											{item.changeCf == 'yes' && <input id={"editCreditReference" + index} name="editCreditReference" class="" type="text" placeholder="0" maxlength="18" defaultValue={item.cfBalance} onChange={(e) => { changeCreditRef(e.target.value, index) }} />}
											{item.changeCf == 'no' && <a id="editCreditReferenceBtn" onClick={() => { if (index === arrIndex) updateCf('yes', index); }} className={`btn ${index === arrIndex ? "" : "disable"}`} style={{}}>Edit</a>}
											{item.changeCf == 'yes' && <a id="cancelCreditReferenceBtn" class="btn" onClick={() => { updateCf('no', index); }} style={{}}>Cancel</a>}
										</td>

										<td id="userReferencePL" className={`${(cr >= 0) ? "" : "red"}`}>{cr >= 0 ? parseFloat(Math.abs(cr)).toFixed(2) : '(' + parseFloat(Math.abs(cr)).toFixed(2) + ')'}</td>

										<td class="border-l"><input onChange={(e) => { updateRemark(e.target.value, index); }} id={"remark" + index} value={item.remark} class="" type="text" placeholder="Remark" /></td>
										<td class="full-amount">
											<a onClick={() => { setshowAgentLog(true); setselectedAgent(item.user) }} id="log" class="btn">Log</a>
										</td>
									</tr>

								)
							})}


							<tr id="totalTempTr" class="total">
								<td class="align-L">Total</td>
								<td id="totalSite" style={{ display: 'none' }}></td>
								<td id="totalCurrencyType" style={{ display: 'none' }}></td>
								<td id="totalBalance">{sum_A1.toFixed(2)}</td>
								<td id="totalAvailableBalance">{sum_B1.toFixed(2)}</td>
								<td id="totalExposure"><span class="red">{'(' + parseFloat(Math.abs(sum_C1)).toFixed(2) + ')'}</span></td>

								<td id="totalCreditReference" class="" colspan="2" >{parseFloat(sum_D1).toFixed(2)}</td>
								<td id="totalReferencePL" className={`${(sum_D2 >= 0) ? "" : "red"}`}>{sum_D2 >= 0 ? parseFloat(Math.abs(sum_D2)).toFixed(2) : '(' + parseFloat(Math.abs(sum_D2)).toFixed(2) + ')'}</td>


								<td></td>
								<td></td>

							</tr>
						</tbody>
					</table>


				</div>

				<div class="submit-wrap" id="settlementBar">
					<ul>
						<li><a onClick={() => { clearAll(); }} id="clearAllBtn" class="btn">Clear All</a></li>
						<li class="submit-payment">
							<input onChange={(e) => { setagentPass(e.target.value); }} id="paymentPassword" type="password" value={agentPass} placeholder="Password" />
							<a onClick={() => { submitPayment(); }} id="submit" class="btn-send">Submit<span id="submitCount">0</span>Payment</a>
						</li>
					</ul>
				</div>

			</div>
			{showAgentLog === true && <AgentLog user={props.user} changeAgentLog={changeAgentLog} selectedAgent={selectedAgent} />}
			{showAllLog === true && <AllLog user={props.user} changeAllLog={changeAllLog} />}
		</React.Fragment>
	)
}
