import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import { toast } from 'react-toastify'
// import AgentLog from './agentLog'

import moment from 'moment';
// import Transparent from './images/transparent.gif'
import Pagination from 'react-js-pagination';
import DatePicker from "react-datepicker";
import { useDetectOutsideClick } from "./useDetectOutsideClick";

// import AllLog from './allLog'
import Loading from '../images/loading40.gif'
import { CasinoDownlines } from './CasinoDownlines';
import Casinouser from '../CasinoControl/userpic.png'
import './Casino.css'





toast.configure()
const cookies = new Cookies();
function CasinoControl(props) {
    const [CasinoHeader, setCasinoHeader] = useState(false)
    const [ClickOpen, setClickOpen] = useState(true)

    const [ClickOpen2, setClickOpen2] = useState(true)
    const [ClickOpen3, setClickOpen3] = useState(true)
    const [DropDown, setDropDown] = useState(false)
    const [casinoDownline, setcasinoDownline] = useState(0)


    const DropDownClick = () => {
        setDropDown(!DropDown)
    }
    const OpenClick = () => {
        setClickOpen(!ClickOpen)
    }
    const CasinoHeaderClick = () => {
        setCasinoHeader(!CasinoHeader)
    }
    const OpenClick2 = () => {
        setClickOpen2(!ClickOpen2)
    }
    const OpenClick3 = () => {
        setClickOpen3(!ClickOpen3)
    }



    return (
        <React.Fragment>

            <style dangerouslySetInnerHTML={{ __html: "\n    *,\n    *:before,\n    *:after {\n        -webkit-box-sizing: border-box;\n        -moz-box-sizing: border-box;\n        box-sizing: border-box\n    }\n" }} />

            <body className={` ${CasinoHeader ? "nav-sm" : "nav-md"}`}>
            {/* <div id="loading" class="loading-wrap" style={{top: '38%',height: '99px',left: '57.3%'}}>
		<ul class="loading">
			<li>
				<img src={Loading}/>
			</li>
			<li>Loading...</li>
		   </ul>
	      </div> */}

                <div className="container body">
                    <div className="main_container">
                        <div className="col-md-3 left_col  ">
                            <div className="left_col scroll-view">
                                <div className="navbar nav_title" style={{ border: 0 }}>
                                    <a href="/dashboard" className="site_title"><i />
                                        <span />
                                    </a>
                                </div>
                                <div className="clearfix" /> {/* menu profile quick info */} {/*
                <div class="profile clearfix">

                    <div class="profile_info">
                        <span>Welcome, </span>
                        <h2>dublinhkd043</h2>

                    </div>
                </div> */}
                                <br /> {/* sidebar menu */}
                                <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
                                    <div className="menu_section active">

                                        {/*
                        <h3>General</h3> */}
                                        <ul className="nav side-menu" style={{}}>
                                            <li onClick = {()=>{setcasinoDownline(0)}} className={`${(casinoDownline===0)? "current-page": "null"}`} ><a ><i className="fa fa-dashboard" /> Dashboard </a></li>
                                            <li><a href="behaviour_analysis"><i className="fa fa-group" /> Behaviour Analysis </a></li>
                                            <li><a href="contribution"><i className="fa fa-pie-chart" /> Contribution </a></li>
                                            <li><a href="turnover"><i className="fa fa-database" /> Turnover </a></li>
                                            <li><a href="source_map"><i className="fa fa-map-marker" /> Source Map </a></li>
                                            <li onClick = {()=>{setcasinoDownline(1)}} className={`${(casinoDownline===1)? "current-page": "null"}`}>
                                                <a >
                                                    <i className="fa fa-file-text" /> Reports <span className="fa fa-chevron-down" /></a>
                                                <ul className="nav child_menu">
                                                    <li><a href="market_summary"> Market summary </a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="top_nav">
                            <div className="nav_menu">
                                <nav>
                                    <div className="nav toggle">
                                        <a id="menu_toggle" onClick={CasinoHeaderClick}><i className="fa fa-bars" /></a>
                                    </div>
                                    <ul className="region_wrap">
                                    </ul>
                                    <ul className="nav navbar-nav navbar-right">
                                        <li className={` ${DropDown ? "open" : ""}`}>
                                            <a href="javascript:;" onClick={() => { DropDownClick() }} className="user-profile dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                                                <img src={Casinouser} alt="..." />
                                                <span></span>
                                               
                                            </a>
                                           
                                        </li>
                                        <li role="presentation" className="dropdown" id="notification_mask">
                                            <div className="waitMe_content vertical" style={{ marginTop: '-10px' }}><div className="waitMe_progress progressBar" style={{}}><div className="waitMe_progress_elem1" style={{ backgroundColor: '#000' }} /></div></div>

                                            <ul id="menu1" className="dropdown-menu list-unstyled msg_list" role="menu">
                                                <li card="notification_card_password">
                                                    <a href="/settings">

                                                        {/* <span class="image"><img src="/static/images/img.jpg" alt="Profile Image" /></span> */}
                                                        <span>
                                                            <span id="title_span" />
                                                            <span className="time" id="time_ago_span" />
                                                        </span>
                                                        <span className="message" id="message_span" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                       
                       
                        <div className="right_col" role="main" style={{ minHeight: '740px' }}>
                            <button id="BackTop" title="Back to top" className="btn-to-top"><i className="fa fa-angle-up" /></button>
                            {casinoDownline ===0   &&   <div className>
                                <div className="clearfix" />
                                <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-12 col_clear" id="mask_ccu" style={{ display: 'block' }}>
                                        <div className="x_panel" style={{ height: 'auto' }}>
                                            <div className="x_title">
                                                <h2>
                                                    <a href="#/info-circle">International Casino<i className="fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title data-original-title="No. of players with transacted bets today" /></a>
                                                </h2>
                                                <ul className="nav navbar-right panel_toolbox">
                                                    {/*
                                    <li><a class="collapse-link"><i class="fa fa-bar-chart"></i></a></li> */}
                                                    <li><a className="collapse-link" id="ccu_chevron">
                                                        <i className="fa fa-chevron-down" /></a></li>
                                                </ul>
                                                <div className="clearfix" />
                                            </div>
                                            <div className="x_content x-open">
                                               
                                                <div className id="mainb">
                                                    <table id="ccu_table" className="table jambo_table bulk_action setTb">
                                                        <tbody>
                                                            <tr parent="amount_PTH">
                                                                <td className="nameTd">
                                                                    <a href="#/plus-square" onClick={() => { OpenClick() }} id="amount_tr_PTH">
                                                                        <i className={` ${ClickOpen ? "fa fa-plus-square" : "fa fa-minus-square"}`} /></a>
                                                                   
                                                                    <span className="currencySp PTH">PBU</span>
                                                                
                                                                </td>

                                                                <td><div class="container1">
                                                                    <label class="switch">
                                                                        <input type="checkbox" class="switch-input" />
                                                                        <span class="switch-label" data-on="On" data-off="Off"></span>
                                                                        <span class="switch-handle"></span>
                                                                    </label>
                                                                </div></td>
                                                            </tr>
                                                            {!ClickOpen === true && <tr className="level2" child="amount_PTH" >
                                                                <td className="nameTd">  <i className="fa fa-plus-square" />
                                                                <span></span></td>

                                                                <td><div class="container1" style={{left: '27px'}}>
                                                                    <label class="switch">
                                                                        <input type="checkbox" class="switch-input" />
                                                                        <span class="switch-label" data-on=" On" data-off="Off" style={{    height: '18px',width: '50px'}} ></span>
                                                                        <span class="switch-handle1" id="toggle-handle"  style={{height:' 18px'}}></span>
                                                                    </label>
                                                                </div></td>
                                                            </tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 col_clear" id="mask_turnover" style={{ display: 'block' }}>
                                        <div className="x_panel" style={{ height: 'auto' }}>
                                            <div className="x_title">
                                                <h2>Indian Casino<i className="fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title data-original-title="No. of players with transacted bets today" /></h2>
                                                <ul className="nav navbar-right panel_toolbox">
                                                    {/*
                                    <li><a class="collapse-link"><i class="fa fa-bar-chart"></i></a></li> */}
                                                    <li><a className="collapse-link" id="amount_chevron">
                                                        <i className="fa fa-chevron-down" /></a></li>
                                                </ul>
                                                <div className="clearfix" />
                                            </div>
                                            <div className="x_content x-collapse-thead">
                                                <div className id="mainb">
                                                    <table id="amount_table" className="table jambo_table bulk_action setTb">

                                                        <tbody>
                                                            <tr parent="amount_PTH">
                                                                <td className="nameTd">
                                                                    <a href="#/plus-square" onClick={() => { OpenClick2() }} id="amount_tr_PTH">
                                                                        <i className={` ${ClickOpen2 ? "fa fa-plus-square" : "fa fa-minus-square"}`} /></a>
                                                                    <span className="currencySp PTH">PBU</span>
                                                                </td>

                                                                <td><div class="container1">
                                                                    <label class="switch">
                                                                        <input type="checkbox" class="switch-input" />
                                                                        <span class="switch-label" data-on="On" data-off="Off"></span>
                                                                        <span class="switch-handle"></span>
                                                                    </label>
                                                                </div></td>
                                                            </tr>
                                                            {!ClickOpen2 === true && <tr className="level2" child="amount_PTH" >
                                                                <td className="nameTd">  <i className="fa fa-plus-square" /><span></span></td>

                                                                <td><div class="container1" style={{left: '27px'}}>
                                                                    <label class="switch">
                                                                        <input type="checkbox" class="switch-input" />
                                                                        <span class="switch-label" data-on=" On" data-off="Off" style={{    height: '18px',width: '50px'}} ></span>
                                                                        <span class="switch-handle1" id="toggle-handle"  style={{height:' 18px'}}></span>
                                                                    </label>
                                                                </div></td>
                                                            </tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 col_clear" id="mask_profit_loss" style={{ display: 'block' }}>
                                        <div className="x_panel" style={{ height: 'auto' }}>
                                            <div className="x_title">
                                                <h2>Others <i className="fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title data-original-title="No. of players with transacted bets today" /> </h2>
                                                <ul className="nav navbar-right panel_toolbox">
                                                    <li>
                                                       
                                                    </li>
                                                    <li><a className="collapse-link" id="pl_chevron">
                                                        <i className="fa fa-chevron-down" /></a></li>
                                                </ul>
                                                <div className="clearfix" />
                                            </div>
                                            <div className="x_content x-collapse">
                                                <div className id="mainb">
                                                    <table id="pl_table" className="table jambo_table bulk_action setTb">
                                                        <tbody>
                                                            <tr parent="pl_PTH">
                                                                <td className="nameTd">
                                                                    <a href="#/plus-square" onClick={() => { OpenClick3() }} id="pl_tr_1_PTH">
                                                                        <i className={` ${ClickOpen3 ? "fa fa-plus-square" : "fa fa-minus-square"}`} /></a>
                                                                    <span className="currencySp PTH">PBU</span>
                                                                </td>
                                                                <td><div class="container1">
                                                                    <label class="switch">
                                                                        <input type="checkbox" class="switch-input" />
                                                                        <span class="switch-label" data-on="On" data-off="Off"></span>
                                                                        <span class="switch-handle"></span>
                                                                    </label>
                                                                </div></td>
                                                            </tr>
                                                            {!ClickOpen3 === true && <tr className="level2" child="pl_PTH_dublinhkd043" >
                                                                <td className="nameTd">
                                                                    <a href="#/plus-square" id="pl_tr_2_PTH_dublinhkd043">
                                                                        <i className="fa fa-plus-square" /></a><span></span></td>
                                                                <td><div class="container1" style={{left: '27px'}}>
                                                                    <label class="switch">
                                                                        <input type="checkbox" class="switch-input" />
                                                                        <span class="switch-label" data-on=" On" data-off="Off" style={{    height: '18px',width: '50px'}} ></span>
                                                                        <span class="switch-handle1" id="toggle-handle"  style={{height:' 18px'}}></span>
                                                                    </label>
                                                                </div></td>
                                                            </tr>}
                                                           
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> }
                            {casinoDownline ===1   && <CasinoDownlines  user={props.user} level={props.level} />
                       } 
                        </div>
                   
                    
                      <footer>
                            <div className="pull-right" id="mask_footer" style={{ display: 'block' }}>
                                <small>Latest settle at <span id="latest_settled_time">02:00 AM</span>. Updated at <span id="updated_time">12:47 PM</span>. </small>
                            </div>
                            <div className="clearfix" />
                        </footer>
                        
                        {/*
        <footer>

        </footer>*/}
                    </div>
                </div>
       
            </body>
        </React.Fragment>
    )
}

export default CasinoControl
