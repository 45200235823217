import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

import "react-datepicker/dist/react-datepicker.css";

const cookies = new Cookies();


const url = window.location.href;
const para = url.split('/');

const eventid = para[4];

export const UnderBetList = (props) => {
   const [betList, setbetList] = useState([]);
   const [eventType, seteventType] = useState('100');
   const [betStatus, setbetStatus] = useState('2');
   const [sDate, setsDate] = useState(moment().format("YYYY-MM-DD"));
   const [eDate, seteDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
   const [startDate, setStartDate] = useState(moment().toDate());
   const [endDate, setendDate] = useState(moment().add(1, 'days').toDate());
   const [SelectTab, setSelectTab] = useState(0)
   const [refresh, setrefresh] = useState(false);



   useEffect(() => {

      var ssid = cookies.get('sid');
      if (!ssid) return;


      setrefresh(true);
      axios.post('https://flagapi.in/agentapi/newagentBetList', {
         sid: ssid,
         pno: window.betlistView,
         eventType: eventType,
         betStatus: betStatus,
         eventId: eventid
      })
         .then(result => {

            setrefresh(false);
            if (result.status === 200) {

               var res = [];
               for (let key in result.data) {

                  if (result.data.hasOwnProperty(key)) {
                     result.data[key].betId = key;
                     res.push(result.data[key]);

                  }
               }

               if (res.length == 0) {
                  toast.warn('No bets in this Market!', { position: toast.POSITION.TOP_CENTER })
               }

               setbetList(res);
               //   console.log(res);
            }

         }

         ).catch(e => {
            //setIsError(true);
         });
   }, [])


   const changeBetStatus = (e) => {
      var selectBox = document.getElementById("betStatus");
      var selectedValue = selectBox.options[selectBox.selectedIndex].value;

      if (selectedValue == 2) {
         setbetStatus('2');
      }
      else if (selectedValue == 3) {
         setbetStatus('3');
      }

   }


   useEffect(() => {

      return () => { window.betlistView = 1; }

   }, []);


   return (
      <React.Fragment>
         <div class="full-wrap" style={{ height: 'calc(100%)' }}>
            {/* <!-- Center Column --> */}
            <div class="over-wrap"  >
               {/* <!-- Loading Wrap --> */}
               {/* {refresh && <div id="loading" class="loading-wrap" style={{}}>
		<ul class="loading">
			<li>
				<img src={Loading}/>
			</li>
			<li>Loading...</li>
		   </ul>
	      </div>} */}
               {/* <!-- Message --> */}
               <div id="message" class="message-wrap success">
                  <a class="btn-close">Close</a>
                  <p></p>
               </div>

               <h2>Bet List</h2>
               <div class="function-wrap">
                  <ul class="input-list">
                     <div class="search-wrap1" id="userSearchUl" style={{ width: "189px" }} >
                        <div>
                           <input class="search-input" style={{ width: "184px", height: '29px' }} type="text" name="userId" id="userId" placeholder="Findbets..." />
                           <button class="search-but1" id="searchUserId">Search</button>
                        </div>
                     </div>
                     <ul class="input-list">
                        <li style={{ marginTop: "4px" }}>
                           <a id="today" onClick={() => { setSelectTab(0) }} className={`${(SelectTab === 0) ? "btn-send" : "btn"}`}>Match Odds</a></li>
                        <li style={{ marginTop: "4px" }}>
                           <a id="Yesterday" onClick={() => { setSelectTab(1) }} className={`${(SelectTab === 1) ? "btn-send" : "btn"}`}>BookMaker</a></li>
                        <li style={{ marginTop: "4px" }}>
                           <a id="yesterday" onClick={() => { setSelectTab(2) }} className={`${(SelectTab === 2) ? "btn-send" : "btn"}`}>Fancy</a></li>
                     </ul>

                  </ul>
               </div>



               {SelectTab === 0 &&





                  <div id="reportDiv" className="over-wrap" style={{ maxHeight: 'calc((100% - 32px) - 93px)' }}>
                     <table id="matchTable" className="table-s" style={{ display: 'table' }}>
                        <tbody>
                           <tr>
                              <th width="8%" className="align-L">MA ID</th>
                              <th width="8%" className="align-L">PL ID</th>
                              <th width="5%" className="align-L">Bet ID
                              </th>
                              <th id="betTime" width="6%" className="align-L">Bet placed</th>
                              <th width="7%" className="align-L">IP Address</th>
                              <th width className="align-L">Market
                              </th>
                              <th width="7%" className="align-L">Selection
                              </th>
                              <th width="4%" className="align-C">Type
                              </th>
                              <th width="4%">Odds req.
                              </th>
                              <th width="8%">Stake
                              </th>
                              <th width="5%">Liability
                              </th>
                              <th width="5%" name="profitOrLossTh">Profit/Loss
                              </th>





                           </tr>
                           {betList.map((item, index) => {
                              var matchName;
                              var runner;
                              var odds;
                              var matchtype;
                              var profit = '';
                              var wl = '-';
                              var sss = '-';
                              var ss = '-';
                              var sup = '-';
                              var ma = '-';
                              if (item.agentList) {
                                 //   console.log(item.agentList.agentList.level1);
                                 if (item.agentList.agentList.level1) wl = item.agentList.agentList.level1;
                                 if (item.agentList.agentList.level2) sss = item.agentList.agentList.level2;
                                 if (item.agentList.agentList.level3) ss = item.agentList.agentList.level3;
                                 if (item.agentList.agentList.level4) sup = item.agentList.agentList.level4;
                                 if (item.agentList.agentList.level5) ma = item.agentList.agentList.level5;

                              }

                              if (item.eventType == '4') {
                                 matchName = 'CRICKET';
                              } else if (item.eventType == '1') {
                                 matchName = 'SOCCER';
                              }
                              else if (item.eventType == '2') {
                                 matchName = 'TENNIS';
                              }

                              if (item.betType == 'fancy') {
                                 runner = item.runnerName;
                                 odds = item.rate + '/' + parseFloat(item.teamName * 100).toFixed(0);
                              }
                              else if (item.betType == 'match') {
                                 odds = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
                                 if (item.teamName == 'A') {
                                    runner = item.runnerName1;
                                 }
                                 else if (item.teamName == 'B') {
                                    runner = item.runnerName2;
                                 }
                                 else if (item.teamName == 'T') {
                                    runner = 'The Draw';
                                 }
                              }

                              if (item.type === 'LAGAI') {
                                 matchtype = 'BACK';
                              }
                              else if (item.type === 'KHAI') {
                                 matchtype = 'LAY';
                              }
                              else {
                                 matchtype = item.type;
                              }

                              if (item.betType == 'fancy' && item.result) {
                                 if (item.type == "YES") {
                                    if (parseFloat(item.rate) <= parseFloat(item.result)) {

                                       profit = parseFloat(item.amount * item.teamName).toFixed(2);
                                    }
                                    else profit = item.amount * (-1);

                                 }
                                 else {
                                    if (parseFloat(item.rate) > parseFloat(item.result)) {
                                       profit = item.amount;
                                    }
                                    else profit = parseFloat((item.amount * item.teamName) * (-1)).toFixed(2);
                                 }
                              }
                              else if (item.betType == 'match' && item.winner) {
                                 if (item.type == "LAGAI") {
                                    if (item.teamName == 'A') {
                                       if (item.winner == "A") profit = parseFloat(item.rate * item.amount).toFixed(2);
                                       else profit = item.amount * (-1);
                                    }
                                    else if (item.teamName == "B") {
                                       if (item.winner == "B") profit = parseFloat(item.rate * item.amount).toFixed(2);
                                       else profit = item.amount * (-1);
                                    }
                                    else if (item.teamName == "T") {
                                       if (item.winner == "T") profit = parseFloat(item.rate * item.amount).toFixed(2);
                                       else profit = item.amount * (-1);
                                    }
                                 }
                                 else if (item.type == "KHAI") {
                                    if (item.teamName == 'A') {
                                       if (item.winner != "A") profit = item.amount;
                                       else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
                                    }
                                    else if (item.teamName == "B") {
                                       if (item.winner != "B") profit = item.amount;
                                       else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
                                    }
                                    else if (item.teamName == "T") {
                                       if (item.winner != "T") profit = item.amount;
                                       else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
                                    }
                                 }

                                 if ((item.selectionIdTie == null || item.selectionIdTie == '') && item.winner == "T") {
                                    profit = 0;
                                 }

                              }


                              return (

                                 <React.Fragment key={index}>
                                    {item.marketName === "Match Odds" &&
                                       <tr id="matchRow0" style={{ display: 'table-row' }}>

                                          {/* {props.level ==='WL' &&  <td id="agentUserId1" class="align-L" >{wl}</td>}
			
			{(props.level ==='WL' || props.level === 'SSS') &&  <td id="agentUserId2" class="align-L" >{ss}</td>} */}

                                          {/* {(props.level ==='WL' || props.level === 'SSS' || props.level === 'SS') &&   <td id="agentUserId3" class="align-L" >{sup}</td>} */}

                                          {/* {(props.level ==='WL' || props.level === 'SSS' || props.level === 'SS' || props.level === 'SUP') &&   <td id="agentUserId3" class="align-L" >{ma}</td>} */}
                                          <td id="agentUserId3" class="align-L" >{item.clientId}</td>
                                          <td className="align-L"><a id="betID" href="javascript: void(0);">{item.betId}</a></td>
                                          <td className="align-L"><span id="betPlaced" className="small-date">{item.betTime}</span></td>
                                          <td id="ip" className="align-L">00.00.000.00</td>
                                          <td id="matchTitle" className="align-L">{matchName}
                                             <img className="fromto" src="/images/transparent.gif" />
                                             <strong>{item.eventName}</strong>
                                             <img className="fromto" src="/images/transparent.gif" />{item.marketName}</td>
                                          <td id="matchSelection" className="align-L">
                                             <a>{runner}</a></td>
                                          <td className="align-C">
                                             <span id="matchType" className={`${item.type === 'LAGAI' || item.type === 'YES' ? "back" : "lay"}`}>{matchtype}</span>
                                          </td>
                                          <td id="matchOddsReq">{odds}</td>
                                          <td id="matchStake">{parseFloat(item.amount).toFixed(2)}</td>
                                          <td id="liability">-</td>
                                          <td>
                                             <span id="pol" className={`${profit >= 0 ? "green" : "red"}`}>{profit >= 0 ? profit : '(' + Math.abs(profit) + ')'}</span></td>
                                       </tr>}
                                 </React.Fragment>
                              )
                           })}



                        </tbody>
                     </table>   </div>
               }
               {SelectTab === 1 &&

                  <div id="reportDiv" className="over-wrap" style={{ maxHeight: 'calc((100% - 32px) - 93px)' }}>
                     <table id="matchTable" className="table-s" style={{ display: 'table' }}>
                        <tbody>
                           <tr>
                              {/* <th width="8%" className="align-L">MA ID</th> */}
                              <th width="8%" className="align-L">PL ID</th>
                              <th width="5%" className="align-L">Bet ID
                              </th>
                              <th id="betTime" width="6%" className="align-L">Bet placed</th>
                              <th width="7%" className="align-L">IP Address</th>
                              <th width className="align-L">Market
                              </th>
                              <th width="7%" className="align-L">Selection
                              </th>
                              <th width="4%" className="align-C">Type
                              </th>
                              <th width="4%">Odds req.
                              </th>
                              <th width="8%">Stake
                              </th>
                              <th width="5%">Liability
                              </th>
                              <th width="5%" name="profitOrLossTh">Profit/Loss
                              </th>





                           </tr>


                           {betList.map((item, index) => {
                              var matchName;
                              var runner;
                              var odds;
                              var matchtype;
                              var profit = '';
                              var sss = '-';
                              var ss = '-';
                              var sup = '-';
                              var ma = '-';
                              if (item.agentList) {
                                 if (item.agentList.agentList.level1) sss = item.agentList.agentList.level1;
                                 if (item.agentList.agentList.level2) ss = item.agentList.agentList.level2;
                                 if (item.agentList.agentList.level3) sup = item.agentList.agentList.level3;
                                 if (item.agentList.agentList.level4) ma = item.agentList.agentList.level4;

                              }

                              if (item.eventType == '4') {
                                 matchName = 'CRICKET';
                              } else if (item.eventType == '1') {
                                 matchName = 'SOCCER';
                              }
                              else if (item.eventType == '2') {
                                 matchName = 'TENNIS';
                              }

                              if (item.betType == 'fancy') {
                                 runner = item.runnerName;
                                 odds = item.rate + '/' + parseFloat(item.teamName * 100).toFixed(0);
                              }
                              else if (item.betType == 'match') {
                                 odds = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
                                 if (item.teamName == 'A') {
                                    runner = item.runnerName1;
                                 }
                                 else if (item.teamName == 'B') {
                                    runner = item.runnerName2;
                                 }
                                 else if (item.teamName == 'T') {
                                    runner = 'The Draw';
                                 }
                              }

                              if (item.type === 'LAGAI') {
                                 matchtype = 'BACK';
                              }
                              else if (item.type === 'KHAI') {
                                 matchtype = 'LAY';
                              }
                              else {
                                 matchtype = item.type;
                              }

                              if (item.betType == 'fancy' && item.result) {
                                 if (item.type == "YES") {
                                    if (parseFloat(item.rate) <= parseFloat(item.result)) {

                                       profit = parseFloat(item.amount * item.teamName).toFixed(2);
                                    }
                                    else profit = item.amount * (-1);

                                 }
                                 else {
                                    if (parseFloat(item.rate) > parseFloat(item.result)) {
                                       profit = item.amount;
                                    }
                                    else profit = parseFloat((item.amount * item.teamName) * (-1)).toFixed(2);
                                 }
                              }
                              else if (item.betType == 'match' && item.winner) {
                                 if (item.type == "LAGAI") {
                                    if (item.teamName == 'A') {
                                       if (item.winner == "A") profit = parseFloat(item.rate * item.amount).toFixed(2);
                                       else profit = item.amount * (-1);
                                    }
                                    else if (item.teamName == "B") {
                                       if (item.winner == "B") profit = parseFloat(item.rate * item.amount).toFixed(2);
                                       else profit = item.amount * (-1);
                                    }
                                    else if (item.teamName == "T") {
                                       if (item.winner == "T") profit = parseFloat(item.rate * item.amount).toFixed(2);
                                       else profit = item.amount * (-1);
                                    }
                                 }
                                 else if (item.type == "KHAI") {
                                    if (item.teamName == 'A') {
                                       if (item.winner != "A") profit = item.amount;
                                       else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
                                    }
                                    else if (item.teamName == "B") {
                                       if (item.winner != "B") profit = item.amount;
                                       else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
                                    }
                                    else if (item.teamName == "T") {
                                       if (item.winner != "T") profit = item.amount;
                                       else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
                                    }
                                 }

                                 if ((item.selectionIdTie == null || item.selectionIdTie == '') && item.winner == "T") {
                                    profit = 0;
                                 }

                              }


                              return (

                                 <React.Fragment key={index}>
                                    {item.marketName === "Bookmaker" &&
                                       <tr id="matchRow0" style={{ display: 'table-row' }}>

                                          {/* {props.level ==='WL' &&  <td id="agentUserId1" class="align-L" >{wl}</td>}
			
			{(props.level ==='WL' || props.level === 'SSS') &&  <td id="agentUserId2" class="align-L" >{ss}</td>} */}

                                          {/* {(props.level ==='WL' || props.level === 'SSS' || props.level === 'SS') &&   <td id="agentUserId3" class="align-L" >{sup}</td>} */}

                                          {/* {(props.level ==='WL' || props.level === 'SSS' || props.level === 'SS' || props.level === 'SUP') &&   <td id="agentUserId3" class="align-L" >{ma}</td>} */}
                                          <td id="agentUserId3" class="align-L" >{item.clientId}</td>
                                          <td className="align-L"><a id="betID" href="javascript: void(0);">{item.betId}</a></td>
                                          <td className="align-L"><span id="betPlaced" className="small-date">{item.betTime}</span></td>
                                          <td id="ip" className="align-L">00.00.000.00</td>
                                          <td id="matchTitle" className="align-L">{matchName}
                                             <img className="fromto" src="/images/transparent.gif" />
                                             <strong>{item.eventName}</strong>
                                             <img className="fromto" src="/images/transparent.gif" />{item.marketName}</td>
                                          <td id="matchSelection" className="align-L">
                                             <a>{runner}</a></td>
                                          <td className="align-C">
                                             <span id="matchType" className={`${item.type === 'LAGAI' || item.type === 'YES' ? "back" : "lay"}`}>{matchtype}</span>
                                          </td>
                                          <td id="matchOddsReq">{odds}</td>
                                          <td id="matchStake">{parseFloat(item.amount).toFixed(2)}</td>
                                          <td id="liability">-</td>
                                          <td>
                                             <span id="pol" className={`${profit >= 0 ? "green" : "red"}`}>{profit >= 0 ? profit : '(' + Math.abs(profit) + ')'}</span></td>
                                       </tr>}
                                 </React.Fragment>
                              )
                           })}


                        </tbody>
                     </table>   </div>
               }
               {SelectTab === 2 &&

                  <div id="reportDiv" className="over-wrap" style={{ maxHeight: 'calc((100% - 32px) - 93px)' }}>
                     <table id="matchTable" class="table-s" style={{ display: 'table' }}>
                        <tbody><tr>
                           <th width="8%" class="align-L">PL ID</th>
                           <th width="5%" class="align-L">Bet ID
                           </th>
                           <th id="betTime" width="6%" class="align-L">Bet taken</th>
                           <th width="7%" class="align-L">IP Address</th>
                           <th width="" class="align-L">Market
                           </th>
                           <th width="7%" class="align-L">Selection
                           </th>
                           <th width="4%" class="align-C">Type
                           </th>
                           <th width="4%">Odds req.
                           </th>
                           <th width="8%">Stake
                           </th>
                           <th width="5%">Liability
                           </th>
                           <th width="5%" name="profitOrLossTh">Profit/Loss
                           </th>
                        </tr>


                           {betList.map((item, index) => {
                              var matchName;
                              var runner;
                              var odds;
                              var matchtype;
                              var profit = '';
                              var sss = '-';
                              var ss = '-';
                              var sup = '-';
                              var ma = '-';
                              if (item.agentList) {
                                 if (item.agentList.agentList.level1) sss = item.agentList.agentList.level1;
                                 if (item.agentList.agentList.level2) ss = item.agentList.agentList.level2;
                                 if (item.agentList.agentList.level3) sup = item.agentList.agentList.level3;
                                 if (item.agentList.agentList.level4) ma = item.agentList.agentList.level4;

                              }

                              if (item.eventType == '4') {
                                 matchName = 'CRICKET';
                              } else if (item.eventType == '1') {
                                 matchName = 'SOCCER';
                              }
                              else if (item.eventType == '2') {
                                 matchName = 'TENNIS';
                              }

                              if (item.betType == 'fancy') {
                                 runner = item.runnerName;
                                 odds = item.rate + '/' + parseFloat(item.teamName * 100).toFixed(0);
                              }
                              else if (item.betType == 'match') {
                                 odds = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
                                 if (item.teamName == 'A') {
                                    runner = item.runnerName1;
                                 }
                                 else if (item.teamName == 'B') {
                                    runner = item.runnerName2;
                                 }
                                 else if (item.teamName == 'T') {
                                    runner = 'The Draw';
                                 }
                              }

                              if (item.type === 'LAGAI') {
                                 matchtype = 'BACK';
                              }
                              else if (item.type === 'KHAI') {
                                 matchtype = 'LAY';
                              }
                              else {
                                 matchtype = item.type;
                              }

                              if (item.betType == 'fancy' && item.result) {
                                 if (item.type == "YES") {
                                    if (parseFloat(item.rate) <= parseFloat(item.result)) {

                                       profit = parseFloat(item.amount * item.teamName).toFixed(2);
                                    }
                                    else profit = item.amount * (-1);

                                 }
                                 else {
                                    if (parseFloat(item.rate) > parseFloat(item.result)) {
                                       profit = item.amount;
                                    }
                                    else profit = parseFloat((item.amount * item.teamName) * (-1)).toFixed(2);
                                 }
                              }
                              else if (item.betType == 'match' && item.winner) {
                                 if (item.type == "LAGAI") {
                                    if (item.teamName == 'A') {
                                       if (item.winner == "A") profit = parseFloat(item.rate * item.amount).toFixed(2);
                                       else profit = item.amount * (-1);
                                    }
                                    else if (item.teamName == "B") {
                                       if (item.winner == "B") profit = parseFloat(item.rate * item.amount).toFixed(2);
                                       else profit = item.amount * (-1);
                                    }
                                    else if (item.teamName == "T") {
                                       if (item.winner == "T") profit = parseFloat(item.rate * item.amount).toFixed(2);
                                       else profit = item.amount * (-1);
                                    }
                                 }
                                 else if (item.type == "KHAI") {
                                    if (item.teamName == 'A') {
                                       if (item.winner != "A") profit = item.amount;
                                       else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
                                    }
                                    else if (item.teamName == "B") {
                                       if (item.winner != "B") profit = item.amount;
                                       else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
                                    }
                                    else if (item.teamName == "T") {
                                       if (item.winner != "T") profit = item.amount;
                                       else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
                                    }
                                 }

                                 if ((item.selectionIdTie == null || item.selectionIdTie == '') && item.winner == "T") {
                                    profit = 0;
                                 }

                              }


                              return (

                                 <React.Fragment key={index}>
                                    {item.betType == "fancy" &&
                                       <tr id="matchRow0" style={{ display: 'table-row' }}>

                                          {/* {props.level ==='WL' &&  <td id="agentUserId1" class="align-L" >{wl}</td>}
			
			{(props.level ==='WL' || props.level === 'SSS') &&  <td id="agentUserId2" class="align-L" >{ss}</td>} */}

                                          {/* {(props.level ==='WL' || props.level === 'SSS' || props.level === 'SS') &&   <td id="agentUserId3" class="align-L" >{sup}</td>} */}

                                          {(props.level === 'WL' || props.level === 'SSS' || props.level === 'SS' || props.level === 'SUP') && <td id="agentUserId3" class="align-L" >{ma}</td>}
                                          <td id="agentUserId3" class="align-L" >{item.clientId}</td>
                                          <td className="align-L"><a id="betID" href="javascript: void(0);">{item.betId}</a></td>
                                          <td className="align-L"><span id="betPlaced" className="small-date">{item.betTime}</span></td>
                                          <td id="ip" className="align-L">00.00.000.00</td>
                                          <td id="matchTitle" className="align-L">{matchName}
                                             <img className="fromto" src="/images/transparent.gif" />
                                             <strong>{item.eventName}</strong>
                                             <img className="fromto" src="/images/transparent.gif" />{item.marketName}</td>
                                          <td id="matchSelection" className="align-L">
                                             <a>{runner}</a></td>
                                          <td className="align-C">
                                             <span id="matchType" className={`${item.type === 'LAGAI' || item.type === 'YES' ? "back" : "lay"}`}>{matchtype}</span>
                                          </td>
                                          <td id="matchOddsReq">{odds}</td>
                                          <td id="matchStake">{parseFloat(item.amount).toFixed(2)}</td>
                                          <td id="liability">-</td>
                                          <td>
                                             <span id="pol" className={`${profit >= 0 ? "green" : "red"}`}>{profit >= 0 ? profit : '(' + Math.abs(profit) + ')'}</span></td>
                                       </tr>}
                                 </React.Fragment>
                              )
                           })}



                        </tbody>
                     </table>
                  </div>
               }
            </div>

         </div>
      </React.Fragment>
   )
}
