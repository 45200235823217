import React from 'react'

export const MarqueeMassage = () => {
    return (
        <React.Fragment>
            <body className="bg-img">
           <div className="announce-wrap marquee-pop">
    <div className="announce-header">
        <h1>Announcement</h1>
    </div>
    <div className="function-wrap">
        {/*
        <ul class="input-list">
            <li><input id="searchDateStr" class="cal-input" type="text" placeholder="YYYY-MM-DD"></li>
            <li><a id="search" href="#" class="btn-send">Search</a></li>
        </ul>
        */}
    </div>
    <div className="announce-content" id="announcementContent">
        <div id="announcementTemplate" className="article-wrap">
            <dl className="article-date">
                <dt id="date_0">31</dt>
                <dd id="month_0">Dec</dd>
                <dd id="year_0">2021</dd>
            </dl>
            <p id="textByLanguageType_0">Event :- Central Districts Women v Canterbury Women .. Market :- CANW 1 Over Runs ... Whole Market Voided Because All Sites Change After Showing Wrong Toss ... Sorry for the Inconvenience Caused</p>
        </div>
        <div id="announcementTemplate" className="article-wrap">
            <dl className="article-date">
                <dt id="date_1">31</dt>
                <dd id="month_1">Dec</dd>
                <dd id="year_1">2021</dd>
            </dl>
            <p id="textByLanguageType_1">Event :- Central Districts v Canterbury ... Market :- B Wiggins Runs, B Wiggins Boundaries, H.M.B Face By B Wiggins ... We have Voided Whole Market Because B Smith came for opening instead of B Wiggins ... Sorry for the Inconveniences Caused</p>
        </div>
        <div id="announcementTemplate" className="article-wrap">
            <dl className="article-date">
                <dt id="date_2">31</dt>
                <dd id="month_2">Dec</dd>
                <dd id="year_2">2021</dd>
            </dl>
            <p id="textByLanguageType_2">Event :- Central Districts Women v Canterbury Women ... Market :- Fall of 4th WKT CANW, Fall of 5th WKT CANW ... Whole Market Voided Because of Wrong Input Offered By Trader Bymistake, So we have Now created New Market by adding L after the
                Market Name ... Sorry for the Inconvenience Caused</p>
        </div>
        <div id="announcementTemplate" className="article-wrap">
            <dl className="article-date">
                <dt id="date_3">31</dt>
                <dd id="month_3">Dec</dd>
                <dd id="year_3">2021</dd>
            </dl>
            <p id="textByLanguageType_3">Event :- Central Districts v Canterbury ... Market :- CD 10 Over Runs ' 89 - 90 ' ( IST 07:43:34 - 07:43:46 ) ... Bets Voided Because of Wrong Commentary ... Sorry for the Inconvenience Caused</p>
        </div>
        <div id="announcementTemplate" className="article-wrap">
            <dl className="article-date">
                <dt id="date_4">31</dt>
                <dd id="month_4">Dec</dd>
                <dd id="year_4">2021</dd>
            </dl>
            <p id="textByLanguageType_4">Event :- Central Districts v Canterbury ... Market :- CAN 2 Over Runs ' 19 - 20 ' ( IST 09:04:58 - 09:05:12 ) ... Bets Voided Because Trader By Mistake Offered Wrong Rate ... Sorry for the Inconvenience Caused</p>
        </div>
        <div id="announcementTemplate" className="article-wrap">
            <dl className="article-date">
                <dt id="date_5">31</dt>
                <dd id="month_5">Dec</dd>
                <dd id="year_5">2021</dd>
            </dl>
            <p id="textByLanguageType_5">Event :- Adelaide Strikers vs Sydney Thunder ....Market :- 'O Davies Runs , O Davies Boundaries ,O Davies Run Bhav &amp; ' H.M.B Face By O Davies ' .. Whole Market Voided because we Offered these Markets by thinking that ' O Davies ' will
                open the innings for ' Sydney Thunder ' but instead it was ' B Cutting ' who came as opener ....we have created new Markets by adding (L) after the markets Name .. Sorry for the Inconvenience Caused</p>
        </div>
        <div id="announcementTemplate" className="article-wrap">
            <dl className="article-date">
                <dt id="date_6">31</dt>
                <dd id="month_6">Dec</dd>
                <dd id="year_6">2021</dd>
            </dl>
            <p id="textByLanguageType_6">Match :- Adelaide Strikers vs Sydney Thunder .. Market :- SYT 0.2 Ball Run .. Whole Market Voided Because of Wrong Rates Offered Bymistake .. Sorry for the Inconveniences Caused</p>
        </div>
        <div id="announcementTemplate" className="article-wrap">
            <dl className="article-date">
                <dt id="date_7">31</dt>
                <dd id="month_7">Dec</dd>
                <dd id="year_7">2021</dd>
            </dl>
            <p id="textByLanguageType_7">Match :- Adelaide Strikers vs Sydney Thunder .. Market :- SYT 18.5 Ball Run .. Whole Market Voided Because By Mistake Offered Wrong Rates .. Sorry for the Inconvenience Caused</p>
        </div>
        <div id="announcementTemplate" className="article-wrap">
            <dl className="article-date">
                <dt id="date_8">31</dt>
                <dd id="month_8">Dec</dd>
                <dd id="year_8">2021</dd>
            </dl>
            <p id="textByLanguageType_8">Event :- Adelaide Strikers vs Sydney Thunder .. Market :- SYT 20 Over Runs ' 170 - 171 ' (IST 14:56:25 - 14:56:37) Bets Voided Because Of Wrong commentary .. Sorry for the Inconvenience Caused</p>
        </div>
        <div id="announcementTemplate" className="article-wrap">
            <dl className="article-date">
                <dt id="date_9">31</dt>
                <dd id="month_9">Dec</dd>
                <dd id="year_9">2021</dd>
            </dl>
            <p id="textByLanguageType_9">Match :- Adelaide Strikers vs Sydney Thunder .. Market .. Bookmaker .. Selection :- Sydney Thunder ' 3 - 6 ' ( IST 14:59:03 - 14:59:05 ) &amp; 6 - 7.5 ( IST 15:08:52 - 15:08:57 ) Bets Voided Because Wrong Odds Offered Bymistake .. Sorry for
                the Inconveniences Caused</p>
        </div>
    </div>
    <div>
        <ul style={{display: 'none'}}>
            <li id="prev"><a href="javascript:void(0);">Prev</a></li>
            <li id="next"><a href="javascript:void(0);">Next</a></li>
            <li id="pageNumber"><a href="javascript:void(0);" /></li>
            <li id="more"><a href="javascript:void(0);">...</a></li>
            <input type="text" id="goToPageNumber" onkeyup="return PageHandler.validatePageNumber(this)" maxLength={6} size={4} />
            <li><a id="goPageBtn">GO</a></li>
        </ul>
        <ul id="pageNumberContent" className="pages">
            <li id="prev"><a href="javascript:void(0);" className="disable" style={{pointerEvents: 'none'}}>Prev</a></li>
            <li id="pageNumber"><a href="javascript:void(0);" className="select" style={{pointerEvents: 'none'}}>1</a></li>
            <li id="pageNumber"><a href="javascript:void(0);">2</a></li>
            <li id="next"><a href="javascript:void(0);">Next</a></li>
        </ul>
    </div>
</div> 
</body>
        </React.Fragment>
    )
}
