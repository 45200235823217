import React, { useState,useEffect } from 'react'
import Cookies from 'universal-cookie';

const cookies = new Cookies();




export default function TotalBox(props) {


    console.log(props.TCB,'remaining_balance');
    return (
        <React.Fragment>
    

    <React.Fragment>
    <div id="totalBox" class="total_box" >
<dl id="totalBalanceDL" class="total_dl" >
    <dt>Total Balance</dt>
    <dd id="totalBalance">PBU {parseFloat(props.RB).toFixed(2)}</dd>
</dl>
<dl id="totalExposureDL" class="total_dl" >
    <dt>Net Exposure</dt>
    <dd id="totalExposure">PBU <span class="red">({parseFloat(props.CE).toFixed(2)})</span></dd>
</dl>

<dl id="availableBalanceDL" class="total_dl" >
    <dt>Total Avail. Bal.</dt>
    <dd id="totalAvailBal">PBU {parseFloat(props.TCB).toFixed(2)}</dd>
</dl>
<dl id="masterBalanceDL" class="total_dl" >
    <dt>Balance</dt>
    <dd id="mastersBalance">PBU {parseFloat(props.TAB).toFixed(2)}</dd>
</dl>

<dl id="masterBalanceDL" class="total_dl" >
    <dt>Available balance</dt>
    <dd id="mastersBalance">PBU <span>{parseFloat(props.totalAvbal).toFixed(2)}</span></dd>
</dl>

<dl id="masterAvailableBalanceDL" class="total_dl" style={{height:'40px'}}>
    <dt></dt>
    <dd id="mastersAvailBal"></dd>
</dl>

<dl id="transferablePLWithUplineDL" class="total_dl" >
    <dt>
    </dt>
    <dd id="transferablePLWithUpline"></dd>
</dl>

</div>
    </React.Fragment>
    </React.Fragment>
    )
}
