import React from 'react'

export const CreditReferencePopup = () => {
    const CreditRefoff=()=>{
        window.close("/agent/banking/creditReferenceLog", "", "width=600, height=650")
    }
    return (
        <React.Fragment>
            <div className="log-wrap" style={{width: '600px'}}>
    <table className="game-team">
        <tbody>
            <tr>
                <td className="game-name">
                    Credit Reference Logs
                    <span>kunal112233</span>
                    <a className="pop-close" onClick={CreditRefoff}><img className="icon-back" src="/images/transparent.gif" />Close</a>
                </td>
            </tr>
        </tbody>
    </table>
    <div className="over-wrap" style={{height: '513px'}}>
        <table style={{display: 'none'}}>
            <tbody>
                <tr id="tempTr">
                    <td className="align-L" id="dateTime" />
                    <td id="oldValue" />
                    <td id="newValue" />
                </tr>
                <tr id="noDataTempTr">
                    <td className="no-data" colSpan={3}>
                        <p>No Data</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <table className="table01">
            <tbody>
                <tr>
                    <th width="20%" className="align-L">Date/Time</th>
                    <th width="40%">Old Value</th>
                    <th width="40%">New Value</th>
                </tr>
            </tbody>
            <tbody id="content">
                <tr id="noDataTempTr">
                    <td className="no-data" colSpan={3}>
                        <p>No Data</p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    {/* Pages List */}
    <div>
        <ul style={{display: 'none'}}>
            <li id="prev"><a href="javascript:void(0);">Prev</a></li>
            <li id="next"><a href="javascript:void(0);">Next</a></li>
            <li id="pageNumber"><a href="javascript:void(0);" /></li>
            <li id="more"><a href="javascript:void(0);">...</a></li>
            <input type="text" id="goToPageNumber" onkeyup="return PageHandler.validatePageNumber(this)" maxLength={6} size={4} />
            <li><a id="goPageBtn">GO</a></li>
        </ul>
        <ul id="pageNumberContent" className="pages" />
    </div>
</div>
        </React.Fragment>
    )
}
