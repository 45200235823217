import React, { useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function Marqueebox() {

  const [Announcement, setAnnouncement] = useState([])

  useEffect(() => {
    var ssid = cookies.get('sid');

    axios.post('https://flagapi.in/agentapi/clientnews', {

      id: ssid,
    })
      .then(result => {
        // if(result.status === 210){
        var arr = [];
        //  console.log(result.data);
        result.data.map((item) => {
          arr.push(item)
        })

        setAnnouncement(arr);
        //  }

      }
      ).catch(e => {
        //setIsError(true);
      });

  }, [])


  return (
    <React.Fragment>
      <div class="marquee-box">
        <h4>News</h4>
        <div class="marquee" >
          <div style={{ width: '100000px', transform: 'translateX(1278px)', animation: '34.623s linear 0s infinite normal none running marqueeAnimation-327153' }} class="js-marquee-wrapper">
            {Announcement.map(function (item, id) {

              var Date = moment(item.date).format('ll');
              return (
                <div class="js-marquee" style={{ marginRight: ' 0px', float: ' left' }}><a><span>{Date}</span> {item.message}</a></div>
              )
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
