import React, { useState } from 'react'
import { Link } from 'react-router-dom';




export const Alert = () => {

    return (
        <React.Fragment>
            <div className="full-wrap" style={{ height: 'calc(100%)' }}>
                <div className="over-wrap" style={{ height: 'inherit' }}>
                    <div id="message" className="message-wrap success">
                        <a className="btn-close">Close</a>
                        <p />
                    </div>
                    <h2>Alert List</h2>
                    <div id="reportDiv" className="over-wrap" style={{ maxHeight: 'calc((100% - 32px) - 93px)' }}>
                        <table id="table_DL" className="table01 table-pt">
                            <tbody>
                                <tr id="head">
                                    <th id="header_eventName" className="align-L">Match Name</th>
                                    <th width="15%">Stake</th>
                                    <th width="15%">Player P/L</th>
                                    <th id="header_profitLoss_downLine" width="15%">Downline P/L</th>
                                    <th id="header_tax_agent_2" width="15%">Super Comm.</th>
                                    <th width="15%">Alert</th>
                                </tr>
                            </tbody>
                            <tbody id="content">
                                <tr id="main_31104959_1">
                                    <td className="align-L">
                                        <Link to="/AlertBets">
                                            <img className="fromto" src="/images/transparent.gif" />
                                            <strong id="_eventName">Perth Scorchers v Sydney Thunder</strong></Link></td>
                                    <td id="_stake"> 20.00</td>
                                    <td id="_profitLoss"> 1.60</td>
                                    <td id="_profitLossDownLine"> 1.60</td>
                                    <td id="_tax2"> 0.00</td>
                                    <td id="_profitLossUpLine">
                                        <span className="red">( 1.60)</span></td>
                                </tr>
                                <tr id="main_31104967_1">
                                    <td className="align-L">
                                        <a href="javascript:void(0);">
                                            <img className="fromto" src="/images/transparent.gif" />
                                            <strong id="_eventName">Melbourne Renegades v Brisbane Heat</strong>
                                        </a></td>
                                    <td
                                        id="_stake"> 20.00</td>
                                    <td id="_profitLoss"> 2.35</td>
                                    <td id="_profitLossDownLine"> 2.35</td>
                                    <td id="_tax2"> 0.00</td>
                                    <td id="_profitLossUpLine"><span className="red">( 2.35)</span></td>
                                </tr>
                                <tr id="main_31144345_1">
                                    <td className="align-L">
                                        <a href="javascript:void(0);">
                                            <img className="fromto" src="/images/transparent.gif" />
                                            <strong id="_eventName">Girona v Osasuna</strong>
                                        </a>
                                    </td>
                                    <td
                                        id="_stake"> 20.00</td>
                                    <td id="_profitLoss"><span className="red">( 3.00)</span></td>
                                    <td id="_profitLossDownLine"><span className="red">( 3.00)</span></td>
                                    <td id="_tax2"> 0.00</td>
                                    <td id="_profitLossUpLine"> 3.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
