import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { toast } from 'react-toastify'
import Transparent from './images/transparent.gif'
import Pagination from 'react-js-pagination';
import DatePicker from "react-datepicker";
import Loading from './images/loading40.gif'
import { Link } from 'react-router-dom';



const cookies = new Cookies();
window.down_day = 3;
toast.configure()
var firstTime = true;

var totalLength1 = 1;
var totalLength2 = 1;

export default function Downline(props) {

	const [agentList, setagentList] = useState([]);
	const [clientList, setclientList] = useState([]);
	const [sDate, setsDate] = useState(moment().format("YYYY-MM-DD"));
	const [eDate, seteDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
	const [startDate, setStartDate] = useState(moment().toDate());
	const [endDate, setendDate] = useState(moment().add(1, 'days').toDate());
	const [agentMenu, setagentMenu] = useState([]);
	const [refresh, setrefresh] = useState(false);


	var sum1 = 0, sum2 = 0, sum3 = 0;


	useEffect(() => {
		let arr = [];
		let obj = { userid: props.user, level: props.level }
		arr.push(obj);
		setagentMenu(arr);
		return () => { firstTime = true; }

	}, [props.user, props.level]);


	const addAgent = (lev, userid) => {
		let obj = { userid: userid, level: lev };
		var arr = [...agentMenu];
		arr.push(obj);
		setagentMenu(arr);

	}

	const popAgent = (index) => {

		var arr = [...agentMenu];

		setagentMenu(arr.splice(0, index + 1));


	}

	useEffect(() => {
		if (agentMenu.length > 0 && firstTime === false) {
			getMarketList();
		}

	}, [agentMenu.length]);


	const getMarketList = () => {

		totalLength1 = 1;
		totalLength2 = 1;

		var ssid = cookies.get('sid');
		if (!ssid) return;

		var start = '';
		var end = '';

		if (window.down_day === 3) {
			start = sDate + ' ' + '09:00:00';
			end = eDate + ' ' + '08:59:00';
		}
		else if (window.down_day === 1) {
			var now = moment();
			start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
			end = now.endOf('day').format("YYYY-MM-DD HH:mm:ss");

		}
		else if (window.down_day === 2) {
			var now = moment().subtract(1, 'days');
			start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
			end = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");


		}
		setrefresh(true);
		axios.post('https://flagapi.in/agentapi/newagentDownlinePl', {
			sid: ssid,
			startDate: start,
			endDate: end,
			agentId: agentMenu[agentMenu.length - 1].userid,

		})
			.then(result => {
				setrefresh(false);
				if (result.status === 200) {

					setagentList(result.data);

					if (result.data.length == 0) {
						totalLength1 = 0;
					}
					if (totalLength1 == 0 && totalLength2 == 0) {
						toast.warn('No bets in this periods!', { position: toast.POSITION.TOP_CENTER })
					}
					if (result.data.length > 0) {
						firstTime = false;
					}

				}
			}
			).catch(e => {
				//setIsError(true);
			});


		axios.post('https://flagapi.in/agentapi/newclientDownlinePl', {
			sid: ssid,
			startDate: start,
			endDate: end,
			agentId: agentMenu[agentMenu.length - 1].userid,


		})
			.then(result => {

				if (result.status === 200) {

					setclientList(result.data);

					if (result.data.length == 0) {
						totalLength2 = 0;
					}
					if (totalLength1 == 0 && totalLength2 == 0) {
						toast.warn('No bets in this periods!', { position: toast.POSITION.TOP_CENTER })
					}
					if (result.data.length > 0) {
						firstTime = false;
					}

				}
			}
			).catch(e => {
				//setIsError(true);
			});
	}



	return (
		<React.Fragment>

			{refresh && <div id="loading" class="loading-wrap" style={{}}>
				<ul class="loading">
					<li>
						<img src={Loading} />
					</li>
					<li>Loading...</li>
				</ul>
			</div>}


			<div class="main_wrap" style={{ height: 'calc(100% - 105px)' }}>
				<div style={{ display: 'flex' }}>
					<h2>Profit/Loss Report by Downline
					</h2>
					<ul class="input-list" style={{ marginLeft: '12px' }}>
						<li id="eventType_checkBox">
							<input type="checkbox" id="events_all" value="0" />All
							<input type="checkbox" name="events" id="events_1" value="1" /> Cricket

							<input type="checkbox" name="events" id="events_2" value="2" /> Soccer
							<input type="checkbox" name="events" id="events_4" value="4" /> Tennis
							<input type="checkbox" name="events" id="events_7" value="7" /> Casino
							<input type="checkbox" id="TS_BINARY" name="TS_BINARY" value="6" />Kabaddi</li></ul>
				</div>

				{/* <!-- function --> */}
				<div class="function-wrap">
					<ul class="input-list">
						<div id="statusCondition" style={{ display: 'none' }}>
							<li>
								<label>Bet Status:</label>
							</li>
							<li>
								<select name="betStatus" id="betStatus"></select>
							</li>
						</div>
						<li>
							<label>Time Zone</label>
						</li>
						<li>
							<select name="timezone" id="timezone">

								<option value="IST" selected="selected">IST(Bangalore / Bombay / New Delhi) (GMT+5:30)</option>

							</select>
						</li>
						<li></li>
						<li>
							<label>Period</label>
						</li>

						<ul class="input-list" style={{ display: 'inline-flex' }}>

							<DatePicker
								selectsStart
								dateFormat="yyyy-MM-dd"
								selected={startDate}
								placeholderText="YYYY-MM-DD"
								className="cal-input"
								onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }}
							/>
							<input id="startTime" disabled="true" class="time-input disable" type="text" placeholder="09:00" maxLength="5" />

							<DatePicker
								selectsEnd
								dateFormat="yyyy-MM-dd"
								placeholderText="YYYY-MM-DD"
								selected={endDate}
								onChange={(date) => { setendDate(date); seteDate(moment(date).format("YYYY-MM-DD")); }}
								className="cal-input"
							/>
							<input id="endTime" disabled="true" class="time-input disable" type="text" placeholder="08:59" maxLength="5" />


						</ul>

						<li style={{ display: 'none' }}>(TimeZone:IST)</li>
					</ul>
					<ul class="input-list">
						<li><a id="today" onClick={() => { firstTime = true; window.down_day = 1; getMarketList(); }} class="btn">Just For Today</a>
						</li>
						<li><a id="yesterday" onClick={() => { firstTime = true; window.down_day = 2; getMarketList(); }} class="btn">From Yesterday</a>
						</li>
						<li style={{ display: 'none' }}><a id="last7days" class="btn">Last 7 days</a>
						</li>
						<li style={{ display: 'none' }}><a id="last30days" class="btn">Last 30 days</a>
						</li>
						<li style={{ display: 'none' }}><a id="last2months" class="btn">Last 2 Months</a>
						</li>
						<li style={{ display: 'none' }}><a id="last3months" class="btn">Last 3 Months</a>
						</li>
						<li><a id="getPL" onClick={() => { firstTime = true; window.down_day = 3; getMarketList(); }} class="btn-send">Get P &amp; L</a>
						</li>
					</ul>
				</div>
				{/* <!-- No Report Message --> */}
				<div id="noReportMessage"></div>

				{/* <!-- function end--> */}
				{/* <!-- Loading Wrap --> */}
				<div id="loading" class="loading-wrap" style={{ display: 'none' }}>
					<ul class="loading">
						<li>
							<img src={Transparent} />
						</li>
						<li>Loading...</li>
					</ul>
				</div>



				{(agentList.length + clientList.length > 0 || firstTime === false) && <div class="agent_path">
					<ul id="agentPath" class="agent_path-L">

						{agentMenu.map((item, index) => {
							var levelname;
							if (item.level == 'ML') {
								levelname = '0';
							}
							else if (item.level == 'WL') {
								levelname = '1';
							}
							else if (item.level == 'SSS') {
								levelname = '2';
							}
							else if (item.level == 'SS') {
								levelname = '3';
							}
							else if (item.level == 'SUP') {
								levelname = '4';
							}
							else if (item.level == 'MA') {
								levelname = '5';
							}




							return (
								<li key={index} id="path5" className={`${(index == agentMenu.length - 1) ? "last_li" : ""}`}>
									<a onClick={() => { if (index == agentMenu.length - 1) { return; } popAgent(index); }}>
										<span className={`lv_${levelname !== '5' ? levelname : "0"}`}>
											{item.level}
										</span>
										<strong>{item.userid}</strong>
									</a>
								</li>

							)
						})}
					</ul>
				</div>}




				{/* <!-- Message --> */}
				<div id="message" class="message-wrap success"> <a class="btn-close">Close</a>
					<p></p>
				</div>


				<div id="reportDiv" class="over-wrap" style={{ maxHeight: 'calc(100% - 32px - 93px - 55px)' }}>

					{(agentList.length + clientList.length > 0 || firstTime === false) && <table id="table_DL" class="table01 table-pt" style={{}}>
						<tbody><tr id="head">
							<th id="header_userName" width="" class="align-L">UID</th>
							{/* <th width="10%" class="">Stake</th> */}
							<th width="10%" class="">Player P/L</th>
							<th id="header_profitLoss_downLine" width="10%" class="">Downline P/L</th>

							<th id="header_tax_agent_3" width="10%" class="" style={{ display: 'none' }}>Master Comm.</th>
							<th id="header_rebate_agent_3" width="10%" class="" style={{ display: 'none' }}>Master Rebate</th>
							<th id="header_tax_agent_2" width="10%" class="" style={{ display: 'none' }}>Super Comm.</th>
							<th id="header_rebate_agent_2" width="10%" class="" style={{ display: 'none' }}>Super Rebate</th>

							<th id="header_tax_agent_1" width="10%" class="">Agent Comm.</th>


							<th width="10%" class="">Upline P/L</th>

						</tr>

						</tbody>
						<tbody id="content">

							{agentList.map((item, index) => {
								var lev;
								var cla;
								if (item.level == 1) {
									lev = 'WL';
									cla = '1';
								}
								else if (item.level == 2) {
									lev = 'SSS';
									cla = '2';
								}
								else if (item.level == 3) {
									lev = 'SS';
									cla = '3';
								}
								else if (item.level == 4) {
									lev = 'SUP';
									cla = '4';
								}
								else if (item.level == 5) {
									lev = 'MA';
									cla = '5';
								}



								var netPnl = item.netPL;
								var dll = parseFloat(item.dll)


								netPnl = parseFloat(netPnl);
								sum1 = sum1 + parseFloat(netPnl);
								//sum2 = sum2 + parseFloat(item.netPL);
								sum3 = sum3 + dll;


								return (
									<tr key={index} id="main_ptt2020_1">
										<td class="align-L">
											<a id="_bySport" class="expand-close"></a>

											<a id="_userName" onClick={() => { addAgent(lev, item.agentId); }} class="ico_account"><span className={`lv_${true ? cla : " "}`}>{lev}</span>{item.agentId}</a>

										</td>
										{/* <td id="_stake">-</td> */}
										<td id="_profitLoss"><span style={(netPnl >= 0) ? { color: 'red' } : { color: '' }}>{(netPnl >= 0) ? '(' + parseFloat(Math.abs(netPnl)).toFixed(2) + ')' : parseFloat(Math.abs(netPnl)).toFixed(2)}</span></td>
										<td id="_profitLossDownLine"><span style={(netPnl >= 0) ? { color: 'red' } : { color: '' }}>{(netPnl >= 0) ? '(' + parseFloat(Math.abs(netPnl)).toFixed(2) + ')' : parseFloat(Math.abs(netPnl)).toFixed(2)}</span></td>

										<td id="_tax3" style={{ display: 'none' }}> 0.00</td>
										<td id="_rebate3" style={{ display: 'none' }}> 0.00</td>
										<td id="_tax2" style={{ display: 'none' }}> 0.00</td>
										<td id="_rebate2" style={{ display: 'none' }}> 0.00</td>

										<td id="_tax1">{(0).toFixed(2)}</td>
										{/* <td id="_rebate1"> 0.00</td> */}

										<td id="_profitLossUpLine" style={(netPnl >= 0) ? { color: '' } : { color: 'red' }}>{(netPnl >= 0) ? parseFloat(Math.abs(netPnl)).toFixed(2) : '(' + parseFloat(Math.abs(netPnl)).toFixed(2) + ')'}</td>

									</tr>
								)
							})}


							{clientList.map((item, index) => {

								sum1 = sum1 + parseFloat(item.netPL);
								var dll = parseFloat(item.dll) * -1;

								sum3 = sum3 + dll;


								return (
									<tr key={index} id="main_ptt2020_1">
										<td class="align-L">
											<a id="_bySport" class="expand-close"></a>
											<Link to='/clientProfile' id="_userName" onClick={() => { props.changePro(3); props.pushAgent(item.clientId, '6'); props.changeView(3); }} class="ico_account"><span className={`lv_${true ? "0" : "0"}`}>PL</span>{item.clientId}</Link>
										</td>

										<td id="_profitLoss"><span style={(item.netPL >= 0) ? { color: '' } : { color: 'red' }}>{(item.netPL >= 0) ? parseFloat(Math.abs(item.netPL)).toFixed(2) : '(' + parseFloat(Math.abs(item.netPL)).toFixed(2) + ')'}</span></td>
										<td id="_profitLossDownLine"><span style={(item.netPL >= 0) ? { color: '' } : { color: 'red' }}>{(item.netPL >= 0) ? parseFloat(Math.abs(item.netPL)).toFixed(2) : '(' + parseFloat(Math.abs(item.netPL)).toFixed(2) + ')'}</span></td>

										<td id="_tax3" style={{ display: 'none' }}> 0.00</td>
										<td id="_rebate3" style={{ display: 'none' }}> 0.00</td>
										<td id="_tax2" style={{ display: 'none' }}> 0.00</td>
										<td id="_rebate2" style={{ display: 'none' }}> 0.00</td>


										<td id="_rebate1"> {(dll).toFixed(2)}</td>

										<td id="_profitLossUpLine" style={(item.netPL >= 0) ? { color: 'red' } : { color: '' }}>{(item.netPL >= 0) ? '(' + parseFloat(Math.abs(item.netPL)).toFixed(2) + ')' : parseFloat(Math.abs(item.netPL)).toFixed(2)}</td>

									</tr>
								)
							})}


							<tr id="tempTotalTr" class="total">
								<td class="align-L">Total</td>
								{/*<td id="_totalStake"> 606,883.40</td>*/}
								{clientList.length == 0 &&
									<td id="_totalProfitLoss"><span style={(sum1 >= 0) ? { color: 'red' } : { color: '' }}>{sum1 >= 0 ? '(' + parseFloat(Math.abs(sum1)).toFixed(2) + ')' : parseFloat(Math.abs(sum1)).toFixed(2)}</span></td>}
								{clientList.length == 0 &&
									<td id="_totalProfitLossDownLine"><span style={(sum1 >= 0) ? { color: 'red' } : { color: '' }}>{sum1 >= 0 ? '(' + parseFloat(Math.abs(sum1)).toFixed(2) + ')' : parseFloat(Math.abs(sum1)).toFixed(2)}</span></td>}


								{clientList.length > 0 &&
									<td id="_totalProfitLoss"><span style={(sum1 >= 0) ? { color: '' } : { color: 'red' }}>{sum1 >= 0 ? parseFloat(Math.abs(sum1)).toFixed(2) : '(' + parseFloat(Math.abs(sum1)).toFixed(2) + ')'}</span></td>}
								{clientList.length > 0 &&
									<td id="_totalProfitLossDownLine"><span style={(sum1 >= 0) ? { color: '' } : { color: 'red' }}>{sum1 >= 0 ? parseFloat(Math.abs(sum1)).toFixed(2) : '(' + parseFloat(Math.abs(sum1)).toFixed(2) + ')'}</span></td>}

								<td id="_totalTax3" style={{ display: 'none' }}> 0.00</td>
								<td id="_totalRebate3" style={{ display: 'none' }}> 0.00</td>
								<td id="_totalTax2" style={{ display: 'none' }}> 0.00</td>
								<td id="_totalRebate2" style={{ display: 'none' }}> 0.00</td>

								{clientList.length > 0 &&
									<td id="_totalTax1">{sum3.toFixed(2)}</td>}
								{clientList.length == 0 &&
									<td id="_totalRebate1"> 0.00</td>}
								{clientList.length > 0 &&
									<td id="_totalProfitLossUpLine" style={(sum1 >= 0) ? { color: 'red' } : { color: '' }}>{sum1 >= 0 ? '(' + parseFloat(Math.abs(sum1)).toFixed(2) + ')' : parseFloat(Math.abs(sum1)).toFixed(2)}</td>
								}

								{clientList.length == 0 &&
									<td id="_totalProfitLossUpLine" style={(sum1 >= 0) ? { color: '' } : { color: 'red' }}>{sum1 >= 0 ? parseFloat(Math.abs(sum1)).toFixed(2) : '(' + parseFloat(Math.abs(sum1)).toFixed(2) + ')'}</td>
								}
							</tr>


						</tbody>
					</table>}
				</div>

			</div>
		</React.Fragment>
	)
}
