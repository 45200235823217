import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import Transparent from './images/transparent.gif'
import Pagination from 'react-js-pagination';
import Loading from './images/loading40.gif'

const cookies = new Cookies();

export default function AllLog(props) {
  const [allLog, setallLog] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(50);
  const [refresh, setrefresh] = useState(true);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = allLog.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    var ssid = cookies.get('sid');
    if (!ssid) return;
    axios.post('https://flagapi.in/agentapi/newmyAccountStatement', {
      sid: ssid,
    }).then(result => {
      setrefresh(false);
      var arr = [];
      var str2 = props.user.toUpperCase();

      result.data.map((item, index) => {
        if (item.fromAgent && item.fromAgent.toUpperCase() === str2) {
          arr.push(item);
        }


      });

      setallLog(arr);

    }
    ).catch(e => {
      //setIsError(true);
    });
  }, [])


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  };



  return (

    <div id="agentlog" class="pop_bg" style={{ top: '0', display: 'block' }}>
      {/*<body class="betbuzz365">*/}
      <div class="log-wrap" style={{ marginTop: '9vh', width: "1280px" }}>
        <table class="game-team">
          <tbody><tr>
            <td class="game-name">
              Banking Logs

              <a onClick={() => { props.changeAllLog(); }} style={{ top: '-1px' }} class="pop-close" ><img class="icon-back" src={Transparent} />Close</a>
            </td>
          </tr>
          </tbody>
        </table>


        {refresh && <div id="loading" class="loading-wrap" style={{}}>
          <ul class="loading">
            <li>
              <img src={Loading} />
            </li>
            <li>Loading...</li>
          </ul>
        </div>}


        <div id="message" class="message-wrap success">
          <a class="btn-close">Close</a>
          <p></p>
        </div>


        <div class="over-wrap" style={{ height: "513px" }}>
          <table style={{ display: 'none' }}>

            <tbody><tr id="tempTr">
              <td id="createDate" class="align-L"></td>
              <td id="userId" class="align-L" style={{ display: 'none' }}></td>
              <td id="deposit">-</td>
              <td id="withdraw">-</td>
              <td id="balance"></td>
              <td id="remark"></td>
              <td>
                <span id="from"></span>
                <img class="fromto" src={Transparent} />
                <span id="to"></span>
              </td>
            </tr>

              <tr id="noDataTempTr">
                <td class="no-data" colSpan="6">
                  <p>No Data</p>
                </td>
              </tr>
            </tbody>
          </table>

          <table id="table_log" class="table01">
            <tbody><tr>
              <th width="11%" class="align-L">Date/Time</th>
              <th width="13%" class="align-L" style={{ display: 'none' }}>UID</th>
              <th width="13%">Deposit</th>
              <th width="13%">Withdraw</th>
              <th width="13%">Balance</th>
              <th width="15%">Remark</th>
              <th width="">From/To</th>
            </tr>

            </tbody><tbody id="content">


              {currentPosts.map((item, index) => {
                var obj1;
                var obj2;
                if (item.amount >= 0) {
                  obj1 = item.amount;
                  obj2 = '-';
                }
                if (item.amount < 0) {
                  obj1 = '-';
                  obj2 = Math.abs(item.amount);
                }
                var obj3 = item.balance;


                return (
                  <tr id="tempTr" key={index}>
                    <td id="createDate" class="align-L">{item.time}</td>
                    <td id="userId" class="align-L" style={{ display: 'none' }}>akshayddl</td>
                    <td id="deposit" class="">{obj1}</td>
                    <td id="withdraw" class="red">{item.amount < 0 ? '(' + obj2 + ')' : obj2}</td>
                    <td id="balance">{obj3}</td>
                    <td id="remark">{item.remark}</td>
                    <td>
                      <span id="from">{item.fromAgent}</span>
                      <img class="fromto" src={Transparent} />
                      <span id="to">{item.toAgent}</span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>


        {allLog.length > 0 && <div className="pages">
          <Pagination
            prevPageText='prev'
            pageRangeDisplayed={4}
            activePage={currentPage}
            nextPageText='next'
            totalItemsCount={allLog.length}
            onChange={handlePageChange}
            itemsCountPerPage={postsPerPage}
            hideFirstLastPages
          />
        </div>}
      </div>

      {/*</body>*/}

    </div>
  )
}