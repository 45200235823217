import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Combine from './Combine'

function App() {
  return (
    <React.Fragment>
    <Router>
      <Switch>
       
       <Route exact path= '/' component={Combine}></Route>
       {/*<Route exact path= '/Runningmarketanlysis/:a/:b' component={RunningFullmarket}></Route>*/}
       <Route exact path='/:eventType' component={Combine}></Route>
       <Route exact path='/:eventType/:subType' component={Combine}></Route>
       <Route exact path='/:eventType/:subType/:belowType' component={Combine}></Route>
   
      </Switch>
    </Router>
  </React.Fragment>
  );
}

export default App;
