import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Cookies from 'universal-cookie';
import Transparent from './images/transparent.gif'
import moment from 'moment'
import Loading from './images/loading40.gif'
import Pagination from 'react-js-pagination';

import { toast } from 'react-toastify'



const cookies = new Cookies();
toast.configure()

export default function BetlistLive(props) {

  const [betLiveList, setbetLiveList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [sortValue, setsortValue] = useState('stake');
  const [sortType, setsortType] = useState('asc');
  const [nTran, setnTran] = useState('100');
  const [eventType, seteventType] = useState('100');
  const [betStatus, setbetStatus] = useState('1');
  const [fetch, setfetch] = useState(true);
  const [refresh, setrefresh] = useState(false);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = betLiveList.slice(indexOfFirstPost, indexOfLastPost);




  useEffect(() => {

    var ssid = cookies.get('sid');
    if (!ssid) return;

    setrefresh(true);
    axios.post('https://flagapi.in/agentapi/agentLiveBetList', {
      sid: ssid,
      sortValue: sortValue,
      sortType: sortType,
      nTran: nTran,
      eventType: eventType,
      betStatus: betStatus

    })
      .then(result => {
        setrefresh(false);
        if (result.status === 200) {
          var res = [];
          for (let key in result.data) {

            if (result.data.hasOwnProperty(key)) {
              result.data[key].betId = key;
              res.push(result.data[key]);


            }
          }
          if (sortType === 'asc' && sortValue === 'stake') {
            res.sort(function (a, b) {
              return parseFloat(a.amount) - parseFloat(b.amount);
            });
          }
          else if (sortType === 'desc' && sortValue === 'stake') {
            res.sort(function (a, b) {
              return parseFloat(b.amount) - parseFloat(a.amount);
            });
          }
          else if (sortType === 'asc' && sortValue === 'time') {
            res.sort(function (a, b) {
              return moment(a.betTime) - moment(b.betTime);
            });
          }
          else if (sortType === 'desc' && sortValue === 'time') {
            res.sort(function (a, b) {
              return moment(b.betTime) - moment(a.betTime);
            });
          }
          setbetLiveList(res);

        }

      }

      ).catch(e => {
        //setIsError(true);
      });


  }, [fetch])



  const changeBetStatus = (e) => {
    var selectBox = document.getElementById("betStatus");
    var selectedValue = selectBox.options[selectBox.selectedIndex].value;
    // console.log(selectedValue);  
    if (selectedValue == 1) {
      setbetStatus('1');
    }
    else if (selectedValue == 2) {
      setbetStatus('2');
    }

  }

  const changeSortValue = (e) => {
    var selectBox = document.getElementById("sortCondition");
    var selectedValue = selectBox.options[selectBox.selectedIndex].value;
    // console.log(selectedValue);  
    if (selectedValue == 1) {
      setsortValue('stake');
    }
    else if (selectedValue == 2) {
      setsortValue('time');
    }

  }

  const changeSortType = (e) => {
    var selectBox = document.getElementById("orderByClause");
    var selectedValue = selectBox.options[selectBox.selectedIndex].value;
    //  console.log(selectedValue);  
    if (selectedValue == 1) {
      setsortType('asc');
    }
    else if (selectedValue == 2) {
      setsortType('desc');
    }

  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  };


  const changenTran = (e) => {
    var selectBox = document.getElementById("limit");
    var selectedValue = selectBox.options[selectBox.selectedIndex].value;
    // console.log(selectedValue);  
    setnTran(selectedValue);

  }



  return (
    <React.Fragment>
      <div class="full-wrap" style={{ height: 'calc(100% - 105px)' }}>

        {/* <!-- Center Column --> */}
        <div class="over-wrap">


          {/* <!-- Loading Wrap --> */}
          {refresh && <div id="loading" class="loading-wrap" style={{}}>
            <ul class="loading">
              <li>
                <img src={Loading} />
              </li>
              <li>Loading...</li>
            </ul>
          </div>}

          {/* <!-- Message --> */}
          <div id="message" class="message-wrap success">
            <a class="btn-close">Close</a>
            <p></p>
          </div>



          <h2>Bet List Live
          </h2>

          <div id="limitCondition" className="function-wrap">

            <ul class="input-list">
              <li id="eventRadioBtnList" onChange={(e) => { seteventType(e.target.value) }}>
                <input type="checkbox" name="events" id="events_0" value="100" checked={eventType === '100'} />All
                <input type="checkbox" name="events" id="events_4" data-eventtype="4" value="4" checked={eventType === '4'} />
                Cricket
                <input type="checkbox" name="events" id="events_1" data-eventtype="1" value="1" checked={eventType === '1'} />
                Soccer
                <input type="checkbox" name="events" id="events_2" data-eventtype="2" value="2" checked={eventType === '2'} />
                Tennis

                {/* <input type="radio" name="events" id="events_2378961" data-eventtype="2378961" value="5" checked={eventType === '5'}/>
            Politics
            <input type="radio" name="events" id="TS_BINARY" data-categorytype="6" value="6" checked={eventType === '6'}/>Binary */}
              </li>
            </ul>



            <ul className="input-list">
              <li>
                <label>Order of display:</label>
                <select onChange={(e) => { changeSortValue(e); }} name="sortCondition" id="sortCondition">

                  <option value="1" selected="">
                    Stake
                  </option>

                  <option value="2">
                    Time
                  </option>

                </select>
                <label> of </label>
                <select onChange={(e) => { changeSortType(e); }} name="orderByClause" id="orderByClause">
                  <option value="1" selected="">Ascending</option>
                  <option value="2">Descending</option>
                </select>
              </li>
              <li>&nbsp;&nbsp;</li>

              <li>
                <label>Last:</label>
                <select onChange={(e) => { changenTran(e); }} name="limit" id="limit">
                  <option value="100">100 Txn</option>
                  <option value="50">50 Txn</option>
                  <option value="25" selected="">25 Txn</option>
                </select>
              </li>
              <li>&nbsp;&nbsp;</li>
              <li>
                <label>Auto Refresh (Seconds)</label>
                <select name="refreshTime" id="refreshTime">
                  <option value="stop">Stop</option>
                  <option value={60}>60</option>
                  <option value={30}>30</option>
                  <option value={15} selected>15</option>
                  <option value={5}>5</option>
                  <option value={2}>2</option>
                </select>
              </li>
              <li>&nbsp;&nbsp;</li>
              <li>
                <label>Bet Status:</label>
                <select onChange={(e) => { changeBetStatus(e); }} name="betStatus" id="betStatus"><option value="1">Matched</option><option value="2">Declared</option></select>
              </li>
              <li>&nbsp;&nbsp;</li>
              <li><a style={{ width: '60px' }} onClick={() => { setfetch(!fetch); }} class="btn-send" id="betListLiveRefresh">Refresh</a></li>
            </ul>
          </div>
          <table id="unMatchTable" class="table-s" style={{ display: 'table' }}><caption id="unMatchedTitle">UnMatched</caption><p class="no-data">You have no bets in this time period.</p></table>
          <table id="pendingTable" class="table-s" style={{ display: 'table' }}><caption id="pendingTitle">Pending</caption><p class="no-data">You have no bets in this time period.</p></table>

          {false && <table id="pendingTable" class="table-s" style={{ display: 'table' }}><caption id="pendingTitle">Pending</caption><p class="no-data">You have no bets in this time period.</p></table>}
          <table id="matchTable" class="table-s" style={{ display: 'table' }}><caption id="matchedTitle">Bets</caption><table id="matchTable" class="table-s" style={{ display: 'table' }}>


            <tbody><tr>

              {props.level === 'SSS' && <th width="8%" class="align-L">SS ID</th>}

              {(props.level === 'SSS' || props.level === 'SS') && <th width="8%" class="align-L">SUP ID</th>}
              {(props.level === 'SSS' || props.level === 'SS' || props.level === 'SUP') && <th width="8%" class="align-L">MA ID</th>}
              <th width="8%" class="align-L">PL ID</th>
              <th width="5%" class="align-L">Bet ID
              </th>
              <th id="betTime" width="6%" class="align-L">Bet taken</th>

              <th width="" class="align-L">Market
              </th>
              <th width="7%" class="align-L">Selection
              </th>

              <th width="4%" class="align-C">Type
              </th>
              <th width="4%">Odds req.
              </th>
              <th width="8%">Stake
              </th>
              <th width="5%">Liability
              </th>

              <th width="5%" name="profitOrLossTh" style={{ display: 'none' }}>Profit/Loss
              </th>

              <th width="3%" id="currencyType_title" style={{ display: 'none' }}>Currency
              </th>
              <th width="5%" id="site_title" style={{ display: 'none' }}>Site
              </th>
              <th width="5%" id="onePT_title" style={{ display: 'none' }}>Percentage PT</th>
              <th width="5%" id="afterPT_title" style={{ display: 'none' }}>PSB After PT</th>
              <th width="5%" id="afterPTInEUR_title" style={{ display: 'none' }}>H.PT in EURO</th>

            </tr>


              {currentPosts.map((item, index) => {

                var matchName;
                var runner;
                var odds;
                var matchtype;
                var liability;
                var sss = '-';
                var ss = '-';
                var sup = '-';
                var ma = '-';
                if (item.agentList) {

                  if (item.agentList.agentList.level1) sss = item.agentList.agentList.level1;
                  if (item.agentList.agentList.level2) ss = item.agentList.agentList.level2;
                  if (item.agentList.agentList.level3) sup = item.agentList.agentList.level3;
                  if (item.agentList.agentList.level4) ma = item.agentList.agentList.level4;

                }
                if (item.eventType == '4') {
                  matchName = 'CRICKET';
                } else if (item.eventType == '1') {
                  matchName = 'SOCCER';
                }
                else if (item.eventType == '2') {
                  matchName = 'TENNIS';
                }

                if (item.betType == 'fancy') {
                  runner = item.runnerName;
                  odds = item.rate + '/' + parseFloat(item.teamName * 100).toFixed(0);
                  liability = '-';
                  if (item.type === 'NO') {
                    liability = parseFloat(parseFloat(item.teamName) * parseFloat(item.amount)).toFixed(2);
                  }
                }
                else if (item.betType == 'match') {
                  odds = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
                  liability = '-';
                  if (item.type === 'KHAI') {
                    liability = parseFloat(parseFloat(item.rate) * parseFloat(item.amount)).toFixed(2);
                  }
                  if (item.teamName == 'A') {
                    runner = item.runnerName1;
                  }
                  else if (item.teamName == 'B') {
                    runner = item.runnerName2;
                  }
                  else if (item.teamName == 'T') {
                    runner = 'The Draw';
                  }
                }

                if (item.type === 'LAGAI') {
                  matchtype = 'BACK';
                }
                else if (item.type === 'KHAI') {
                  matchtype = 'LAY';
                }
                else {
                  matchtype = item.type;
                }


                return (
                  <tr id="matchRow0" style={{ display: 'table-row' }}>

                    {props.level === 'SSS' && <td id="agentUserId1" class="align-L" >{ss}</td>}

                    {(props.level === 'SSS' || props.level === 'SS') && <td id="agentUserId2" class="align-L" >{sup}</td>}

                    {(props.level === 'SSS' || props.level === 'SS' || props.level === 'SUP') && <td id="agentUserId3" class="align-L" >{ma}</td>}

                    <td id="agentUserId4" class="align-L" style={{ display: 'none' }}></td>

                    <td id="agentUserId5" class="align-L" style={{ display: 'none' }}></td>

                    <td id="playerId" class="align-L">{item.clientId}</td>
                    <td class="align-L"><a id="betID" href="javascript: void(0);">{item.betId}</a></td>
                    <td class="align-L"><span id="betPlaced" class="small-date">{item.betTime}</span></td>

                    <td id="matchTitle" class="align-L">{matchName}<img class="fromto" src={Transparent} /><strong>{item.eventName}</strong><img class="fromto" src={Transparent} />{item.betType == 'match' ? item.marketName : 'FANCY_BET'}</td>
                    <td id="matchSelection" class="align-L"><a >{runner}</a></td>

                    <td class="align-C"><span id="matchType" className={`${item.type === 'LAGAI' || item.type === 'YES' ? "back" : "lay"}`}>{matchtype}</span></td>
                    <td id="matchOddsReq">{odds}</td>
                    <td id="matchStake">{parseFloat(item.amount).toFixed(2)}</td>
                    <td id="liability"><span class="red">{liability === '-' ? liability : '(' + liability + ')'}</span></td>

                    <td style={{ display: 'none' }}><span id="pol" class="small-date" style={{ display: 'none' }}></span></td>

                    <td id="currencyType" style={{ display: 'none' }}>PBU</td>
                    <td id="site" style={{ display: 'none' }}>SKYEXCHANGE</td>
                    <td id="onePT" style={{ display: 'none' }}>0.30</td>
                    <td id="afterPT" style={{ display: 'none' }}>3.00</td>
                    <td id="afterPTInEUR" style={{ display: 'none' }}>0.24</td>
                  </tr>

                )
              })}

            </tbody>

          </table></table>
          {/* 
    <!-- Report Table --> */}


          {betLiveList.length === 0 && <p class="no-data">No bets in this time period.</p>}
          {/* <!-- Report Table --> */}
          <div id="report" stylestyle={{ display: 'block' }}>

            {betLiveList.length > 0 && <p class="table-other" style={{ marginTop: ' 5px' }}>Bet List is shown net of commission.
            </p>}
            {betLiveList.length > 0 && <div className="pages">
              <Pagination
                prevPageText='prev'
                pageRangeDisplayed={3}
                activePage={currentPage}
                nextPageText='next'
                totalItemsCount={betLiveList.length}
                onChange={handlePageChange}
                itemsCountPerPage={postsPerPage}
                hideFirstLastPages
              />
            </div>}

          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
