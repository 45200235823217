import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Transparent from './images/transparent.gif'
import Pagination from 'react-js-pagination';
import DatePicker from "react-datepicker";
import Loading from './images/loading40.gif'

import "react-datepicker/dist/react-datepicker.css";

const cookies = new Cookies();
window.bet_day = 3;
window.betlistView = 1;
toast.configure()

export default function BetList(props) {
  const [MatchList, setMatchList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [EventType, seteventType] = useState('100');
  const [betStatus, setbetStatus] = useState('2');
  const [sDate, setsDate] = useState(moment().format("YYYY-MM-DD"));
  const [eDate, seteDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setendDate] = useState(moment().add(1, 'days').toDate());
  const [refresh, setrefresh] = useState(false);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = MatchList.slice(indexOfFirstPost, indexOfLastPost);



  useEffect(() => {
    var ssid = cookies.get('sid');
    if (!ssid) return;
    setrefresh(true);
    axios.post('https://flagapi.in/agentapi/DeclareddMatchList', {
      sid: ssid,
      eventType: EventType
    })
      .then(result => {
        setrefresh(false);

        setMatchList(result.data);
        if (result.status === 200) {

          result.data.map(item => {

            item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
            return item;
          })

          result.data.sort(function (a, b) {
            return moment(a.marketStartTime) - moment(b.marketStartTime);
          });

          var obj = [];
          result.data.map((item) => {

            if (moment() >= moment(item.marketStartTime)) {
              obj.push(item);
            }


          });
          setMatchList(result.data);

          //     setInplay(result.data);    
        }
      }

      ).catch(e => {
        //setIsError(true);
      });
  }, [])


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  };



  const changeBetStatus = (e) => {
    var selectBox = document.getElementById("betStatus");
    var selectedValue = selectBox.options[selectBox.selectedIndex].value;

    if (selectedValue == 2) {
      setbetStatus('2');
    }
    else if (selectedValue == 3) {
      setbetStatus('3');
    }

  }




  return (
    <React.Fragment>
      <div class="full-wrap" style={{ height: 'calc(100%)' }}>
        {/* <!-- Center Column --> */}
        <div class="over-wrap" style={MatchList.length === 0 ? { height: 'inherit' } : { height: 'fit-content' }} >
          {/* <!-- Loading Wrap --> */}
          {refresh && <div id="loading" class="loading-wrap" style={{}}>
            <ul class="loading">
              <li>
                <img src={Loading} />
              </li>
              <li>Loading...</li>
            </ul>
          </div>}
          {/* <!-- Message --> */}
          <div id="message" class="message-wrap success">
            <a class="btn-close">Close</a>
            <p></p>
          </div>

          <h2>Bet List</h2>
          <ul className="input-list">
            <li id="eventRadioBtnList" onChange={(e) => { seteventType(e.target.value) }}>
              <input type="radio" id="TS_BINARY" data-categorytype={6} />All
              <input type="radio" name="events" id="events_1" data-eventtype={1} defaultValue={1} /> Cricket

              <input type="radio" name="events" id="events_2" data-eventtype={2} defaultValue={2} /> Soccer

              <input type="radio" name="events" id="events_4" data-eventtype={4} defaultValue={4} /> Tennis
              <input type="radio" name="events" id="events_7" data-eventtype={7} defaultValue={7} /> Casino
              {/* <input type="radio" name="events" id="events_7" data-eventtype={7} defaultValue={7} /> Horse Racing */}


              {/* <input type="radio" name="ELECTION_fancy" id="ELECTION_fancy" data-eventtype={2378962} data-categorytype={2} defaultValue="true" /> Election/Fancy Bet */}
              {/* <input type="radio" name="FANCYBET_fancy" id="FANCYBET_fancy" data-eventtype={9999999} data-categorytype={2} defaultValue={9999999} /> FancyBet */}
              {/* <input type="radio" id="TS_BINARY" data-categorytype={6} />Binary */}
            </li>
          </ul>
          <div class="function-wrap">
            <ul class="input-list">
              <li><label>Period</label></li>
              <li>

                <ul class="input-list" style={{ display: 'inline-flex' }}>

                  <DatePicker
                    selectsStart
                    dateFormat="yyyy-MM-dd"
                    selected={startDate}
                    placeholderText="YYYY-MM-DD"
                    className="cal-input"
                    onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }}
                  />
                  <input id="startTime" disabled="true" class="time-input " style={{ height: '25px', marginTop: '4px' }} type="text" placeholder="09:00" maxlength="5" />
                  <span>to</span>
                  <DatePicker
                    selectsEnd
                    dateFormat="yyyy-MM-dd"
                    placeholderText="YYYY-MM-DD"
                    selected={endDate}
                    onChange={(date) => { setendDate(date); seteDate(moment(date).format("YYYY-MM-DD")); }}
                    className="cal-input"
                  />
                  <input id="endTime" disabled="true" class="time-input " style={{ height: '25px', marginTop: '4px' }} type="text" placeholder="08:59" maxlength="5" />

                  <ul class="input-list" style={{ marginTop: "5px", marginLeft: "2px" }}>
                    {/* <li><a id="today"  class="btn">Just For Today</a></li>
            <li><a id="Yesterday"  class="btn">From Yesterday</a></li> */}
                    <li style={{ display: 'none' }}><a id="yesterday" class="btn">From Yesterday</a></li>
                    <li style={{ display: 'none' }}><a id="last7days" class="btn">Last 7 days</a></li>
                    <li style={{ display: 'none' }}><a id="last30days" class="btn">Last 30 days</a></li>
                    <li style={{ display: 'none' }}><a id="last2months" class="btn">Last 2 Months</a></li>
                    <li style={{ display: 'none' }}><a id="last3months" class="btn">Last 3 Months</a></li>
                    <li>
                      <a id="getPL" onClick={() => { window.bet_day = 3; window.betlistView = 1; }} class="btn-send">Get History</a></li>
                  </ul>

                </ul>


              </li>


              <li style={{ display: 'none' }}>(TimeZone:IST)</li>
            </ul>
          </div>




          <div id="reportDiv" className="over-wrap" style={{ maxHeight: 'calc(100% - 32px - 93px)' }}>
            <table id="table_DL" className="table01 table-pt" style={{}}>
              <tbody>
                <tr id="head">
                  <th id="header_eventName" width className="align-L">UID</th>
                  <th width="15%" className>Stake</th>
                  <th width="15%" className>Player P/L</th>
                  <th id="header_profitLoss_downLine" width="15%" className>Downline P/L</th>
                  <th id="header_tax_agent_3" width="15%" className style={{ display: 'none' }}>Master Comm.</th>
                  <th id="header_rebate_agent_3" width="15%" className style={{ display: 'none' }}>Master Rebate</th>
                  <th id="header_tax_agent_2" width="15%" className>Super Comm.</th>
                  <th id="header_rebate_agent_2" width="15%" className style={{ display: 'none' }}>Super Rebate</th>
                  <th id="header_payout_agent_1" width="15%" className style={{ display: 'none' }}>SS PT</th>
                  <th id="header_tax_agent_1" width="15%" className style={{ display: 'none' }}>SS Comm.</th>
                  <th id="header_rebate_agent_1" width="15%" className style={{ display: 'none' }}>SS Rebate</th>
                  <th id="header_profitLoss_agent_1" width="15%" className style={{ display: 'none' }}>SS Total</th>
                  <th width="15%" className>Upline P/L</th>
                </tr>
              </tbody>
              <tbody id="content">
                {currentPosts.map(function (item, index) {

                  return (

                    <tr id="main_1882245_0">
                      <td className="align-L">
                        <a id="_byMarket" href="javascript:void(0);" className="expand-close" />

                        <a >
                          {item.sportId === "4" &&
                            <span id="_eventType">Cricket </span>}
                          {item.sportId === "1" &&
                            <span id="_eventType">Soccer </span>}
                          {item.sportId === "2" &&
                            <span id="_eventType">Tennis </span>}
                          <img className="fromto" src="/images/transparent.gif" />
                          <strong id="_eventName"><a href={`/UnderBetList/${item.eventId}`}>{item.eventName}</a></strong></a>
                      </td>
                      <td id="_stake"> 00.00</td>
                      <td id="_profitLoss"><span className="red">00.00</span></td>
                      <td id="_profitLossDownLine"><span className="red">00.00</span></td>
                      <td id="_tax3" style={{ display: 'none' }}> 0.00</td>
                      <td id="_rebate3" style={{ display: 'none' }}> 0.00</td>
                      <td id="_tax2"> 0.00</td>
                      <td id="_rebate2" style={{ display: 'none' }}> 0.00</td>
                      <td id="_payout1" style={{ display: 'none' }}> 0.00</td>
                      <td id="_tax1" style={{ display: 'none' }}> 0.00</td>
                      <td id="_rebate1" style={{ display: 'none' }}> 0.00</td>
                      <td id="_profitLoss1" style={{ display: 'none' }}> 0.00</td>
                      <td id="_profitLossUpLine"> 0.00</td>
                    </tr>

                  )
                })}

                <tr id="tempTotalTr" className="total">
                  <td className="align-L">Total</td>
                  <td id="_totalStake"> 00.00</td>
                  <td id="_totalProfitLoss"><span className="red">( 00.00)</span></td>
                  <td id="_totalProfitLossDownLine"><span className="red">( 00.00)</span></td>
                  <td id="_totalTax3" style={{ display: 'none' }}> 0.00</td>
                  <td id="_totalRebate3" style={{ display: 'none' }}> 0.00</td>
                  <td id="_totalTax2"> 0.00</td>
                  <td id="_totalRebate2" style={{ display: 'none' }}> 0.00</td>
                  <td id="_totalPayout1" style={{ display: 'none' }}> 0.00</td>
                  <td id="_totalTax1" style={{ display: 'none' }}> 0.00</td>
                  <td id="_totalRebate1" style={{ display: 'none' }}> 0.00</td>
                  <td id="_totalProfitLoss1" style={{ display: 'none' }}> 0.00</td>
                  <td id="_totalProfitLossUpLine"> 00.00</td>
                </tr>
              </tbody>
            </table>

            {MatchList.length > 0 && <div className="pages">
              <Pagination
                prevPageText='prev'
                pageRangeDisplayed={3}
                activePage={currentPage}
                nextPageText='next'
                totalItemsCount={MatchList.length}
                onChange={handlePageChange}
                itemsCountPerPage={postsPerPage}
                hideFirstLastPages
              />
            </div>}
          </div>    </div>
      </div>

    </React.Fragment>
  )
}
